import { useEffect, useState } from 'react';
import { getDatos } from '../gestionDatos/getDatos';
import {registrarLogEventos, reportarError, presentarError, crearTabla, valorNumero, recuperarDatoLocal, isFlagUpdate, superaTiempoActualizacion, guardarDatoLocal, quitarTildes, presentarLog, esformatoNumero, dividirLista, getStringLink} from '../helpers/funciones';
import Loader from '../recVisual/Loader';

import "../estilos/CT_Contenedor.css";
import "../estilos/IM_Imagenes.css";
import "../estilos/TX_Textos.css";
import "../estilos/BF_BordesFondos.css";
import "../estilos/IP_Inputs.css";


import ic_logo_mg2 from '../recGraficos/ic_logo_mg2.png';
import ic_salir from '../recGraficos/ic_salir_w.png';
import ic_jugadorsinimagen from '../recGraficos/ic_jugadorsinimagen.png';
import ic_torneo_gris from '../recGraficos/ic_torneo_gris_w.png';
import DinamicoElementosTorneo from '../gestionVisual/DinamicoElementosTorneo';
import ShowError from '../recVisual/ShowError';
import { C_colorAccent, C_uniformeGrisOscuro, C_uniformeRojo, C_uniformeGrisClaro, C_uniformeBlanco,  C_colorPrimaryDark, ID_MODULO_BASE, ID_MODULO_DETALLE_TORNEO, ID_MODULO_PRINCIPAL_INVITADO, C_uniformeVerdeClaro, C_colorTextoSinInfo, C_uniformeAzulClaro } from '../constantes/generales';


const nomClase="Principal_Invitados_WB";
const idModulo=ID_MODULO_PRINCIPAL_INVITADO;

let idUser="";
let tipoUser="invitado";
let idSede="-1";

let cancelarBusqueda=false;

let paramsAjustado="";
let esEmpresa=false;
let esSede=false;
let numId="";

let procesarFiltro=false;


var infoTorneoO = [];
var infoTorneo = [];
var infoTorneoBusqueda = [];
function crearInfoTorneo(datos){  
    const nomFuncion="crearInfoTorneo";

    try{
        let torneo = {
            idtorneo:"",
            escudo:"",
            nombre:"",
            activo:"",
            empresa:"",
            sede:"",
            idsede:"",
            registro:"",
            idempresa:""
        }
    
        infoTorneoO = [];
        infoTorneo = [];
        infoTorneoBusqueda = [];
    
        let i, j;
        for(i=0;i<datos.length;i++){
            let registro=Object.assign({} , torneo);        
    
            for(j=0;j<datos[i].length;j++){
                if(j===0) registro.idtorneo=datos[i][j];
                if(j===1) registro.escudo=datos[i][j];
                if(j===2) registro.nombre=datos[i][j];
                if(j===3) registro.activo=datos[i][j];
                if(j===4) registro.empresa=datos[i][j];
                if(j===5) registro.sede=datos[i][j];
                if(j===6) registro.idsede=datos[i][j];
                if(j===7) registro.registro= "" + i;
                if(j===8) registro.idempresa=datos[i][j];
            }
    
            datos[i][7] = "" + i;
            //registro.registro=i;
            //if(datos[i].length>7) datos[i][7] = "" + i;
            //else if(datos[i].length===6) datos[i].push("" + i);

            let datosTemp=JSON.parse(JSON.stringify(datos[i]));
            datosTemp[2]=quitarTildes(datosTemp[2]).toUpperCase();
            datosTemp[4]=quitarTildes(datosTemp[4]).toUpperCase();
            datosTemp[5]=quitarTildes(datosTemp[5]).toUpperCase();

    
            infoTorneoO.push(registro);
            infoTorneo.push(datos[i]);
            infoTorneoBusqueda.push(datosTemp);

            
        }


    }catch(err){
        reportarError( nomClase, nomFuncion, "", err);
    }

    
}

var infoTorneosDestacadosO = [];
var infoTorneosDestacados = [];
var infoTorneosRecientes = [];
function crearInfoDestacados(datos){   
    const nomFuncion="crearInfoDestacados";
    
    try{
        let torneo = {
            idtorneo:"",
            escudo:"",
            nombre:"",
            activo:"",
            empresa:"",
            sede:"",
            idsede:""
        }

        infoTorneosDestacadosO = [];
        infoTorneosDestacados = [];
    
        let i, j;
        for(i=0;i<datos.length;i++){
            let registro=Object.assign({} , torneo);
            for(j=0;j<datos[i].length;j++){
                if(j===0) registro.idtorneo=datos[i][j];
                if(j===1) registro.escudo=datos[i][j];
                if(j===2) registro.nombre=datos[i][j];
                if(j===3) registro.activo=datos[i][j];
                if(j===4) registro.empresa=datos[i][j];
                if(j===5) registro.sede=datos[i][j];
                if(j===6) registro.idsede=datos[i][j];
            }
    
            infoTorneosDestacadosO.push(registro);
            infoTorneosDestacados.push(datos[i]);
        }

        

    }catch(err){
        reportarError(nomClase, nomFuncion, "", err);
    }

}



const Principal_Invitados = ({idUsuario="-1", params, telInvitado, modOrigen, paramOrigen, definirModulo}) => {
    const [esError, setEsError] = useState(0);
    const [filtro, setFiltro] = useState("");
    const [loading, setLoading] = useState(false);
    const [datosTorneos, setDatosTorneos] = useState([]);

    const [limitado, setLimitado] = useState(false);

    const [esAccesoLink, setEsAccesoLink] = useState(false);
    const [nomSedeUS, setNomSedeUS] = useState("");

    const [nombreEmpresaUS, setNombreEmpresaUS] = useState("");
    const [nombreSedeUS, setNombreSedeUS] = useState("");

    const [infoTorneosDestacadosUS, setInfoTorneosDestacadosUS] = useState([]);
    const [infoTorneosRecientesUS, setInfoTorneosRecientesUS] = useState([]);

    idUser=idUsuario;
    let filtroSede="";
    

    var respuesta = {
        datos: "",
        exito: false,
        conectado: false,
        hayDatos: false,
        mensaje: ''
    }

    useEffect(() => {
        const nomFuncion="useEffect";

        try{   

            paramsAjustado=params;
            
            let actualizar=false;

            actualizar= (isFlagUpdate() || superaTiempoActualizacion(idModulo));
                  
            if(actualizar){
                setLoading(true);
    
                getDatos()
                .get("consultarTabla","getInfoEspectadores","INFO_BUSQUEDA_TORNEOS","","0")
                .then((res) => {
                    respuesta=res;
                    if (respuesta.exito) {   
                        presentarLog(nomClase + " - SOLICITA_DATOS"); 

                        guardarDatoLocal("ultAcceso" + idModulo,Date.now());
                        guardarDatoLocal("datos" + idModulo, respuesta.datos);


                        crearInfoTorneo(crearTabla(respuesta.datos,"1"));
                        crearInfoDestacados(crearTabla(respuesta.datos,"2"));

                        setInfoTorneosDestacadosUS(infoTorneosDestacados);

    
                        if(params!==""){
                            let datos=dividirLista(params,"#",2);
                            if(datos[0]==="E") esEmpresa=true;
                            if(datos[0]==="S") esSede=true;
                            if(esformatoNumero(datos[1]) && valorNumero(datos[1])>0) numId=datos[1];

                            if((esEmpresa || esSede) && numId!==""){                                
                                let nomFiltro=buscarSede(params);
                                procesarFiltro=false;
                                setNomSedeUS(nomFiltro);
                                setFiltro(nomFiltro);
                            }
                            else procesarRecientes();
                            
                        } 
                        else{
                                filtroSede="";
                                procesarRecientes();     
                        } 
                        
                        let telInvitado=recuperarDatoLocal("telInvitado");

                        let identificador2="0";
                        if(esEmpresa || esSede) identificador2="3";
                        registrarLogEventos(idModulo, "1", telInvitado, identificador2 );
        
                    } else {
                        if(respuesta.conectado){
                            reportarError( nomClase, nomFuncion, respuesta.mensaje, "");
                            setEsError(1);
                        } 
                        else{    
                            presentarError(nomClase,nomFuncion,respuesta.mensaje,"");                        
                            setEsError(2);
                        } 
                    }
        
                    setLoading(false);
                    
                });
            } else{
                presentarLog(nomClase + " - RECUPERA_DATOS"); 
                let datosModulo=recuperarDatoLocal("datos" + idModulo);

                if(datosModulo!==""){
                    crearInfoTorneo(crearTabla(datosModulo,"1"));
                    crearInfoDestacados(crearTabla(datosModulo,"2"));

                    setInfoTorneosDestacadosUS(infoTorneosDestacados);
    
                    if(params!==""){
                        let datos=dividirLista(params,"#",2);
                        if(datos[0]==="E") esEmpresa=true;
                        if(datos[0]==="S") esSede=true;
                        if(esformatoNumero(datos[1]) && valorNumero(datos[1])>0) numId=datos[1];

                        if((esEmpresa || esSede) && numId!==""){
                            let nomFiltro=buscarSede(params);
                            procesarFiltro=false;
                            setNomSedeUS(nomFiltro);
                            setFiltro(nomFiltro);
                        }
                        else procesarRecientes();
                        
                    } 
                    else{
                            filtroSede="";
                            procesarRecientes();     
                    } 
                    //procesarRecientes();    
                }   
            }

        }catch(err){
            reportarError( nomClase, nomFuncion, "", err);
        }               

    }, []);

    useEffect(() => {
        const nomFuncion="useEffect.Filtro";

        
        let datosTemp=[];
        try{
     
                let i;
                let max=30;

                cancelarBusqueda=false;
                let filtroAjustado=quitarTildes(filtro).toUpperCase();
        
                for(i=0;i<infoTorneoBusqueda.length;i++){      
                    if(cancelarBusqueda) return;

                    let esFiltro=false;   

                    if(procesarFiltro){
                        let texto=infoTorneoBusqueda[i][2];
                        let texto2=infoTorneoBusqueda[i][4];
                        let texto3=infoTorneoBusqueda[i][5];
            
                        if(filtro !== ""){
                            if(texto.includes(filtroAjustado) || texto2.includes(filtroAjustado) || texto3.includes(filtroAjustado) ) esFiltro=true;
                        }
                    }
                    else{
                        if(esEmpresa && numId!=="" && numId===infoTorneoBusqueda[i][8]) esFiltro=true;
                        if(esSede && numId!=="" && numId===infoTorneoBusqueda[i][6]) esFiltro=true;
                    }
        
                    
        
                    if(esFiltro){
                        datosTemp.push(infoTorneo[i]);
                    }

                    if(datosTemp.length>=max && procesarFiltro) {                    
                        i=infoTorneoBusqueda.length;
                    }
        
                }

                if(procesarFiltro && datosTemp.length>=max) setLimitado(true);
                else setLimitado(false);

                
            
            


        }catch(err){
            reportarError( nomClase, nomFuncion, "", err);
        }  

        setDatosTorneos(datosTemp);
        

    }, [filtro]);

    const cambiarFiltro  = (elemento) => {        
        cancelarBusqueda=true;
        setFiltro(elemento.target.value.toUpperCase());
    };

    const procesarRecientes = () => {
        const nomFuncion="procesarRecientes";

        try{
            if(infoTorneo.length>0){
                let torneosRecientes = JSON.parse(localStorage.getItem('torneosRecientes'));
    
                if(torneosRecientes!==null &&  torneosRecientes!==""){            
    
                    torneosRecientes=torneosRecientes.replaceAll(":","");
    
                    let idtorneosRec=torneosRecientes.split(",");
    
                    if(idtorneosRec.length>0){
    
                        let listaRecientes=[];
                        let i, j, k;
    
                        for(i=idtorneosRec.length-1;i>=0;i--){
                            if(valorNumero(idtorneosRec[i])>0){
                                for(j=0;j<infoTorneo.length;j++){
                                    if(idtorneosRec[i]===infoTorneo[j][0]){
                                        if(infoTorneosDestacados.length>0){
                                            let encontrado=false;
                                            for(k=0;k<infoTorneosDestacados.length;k++){
                                                if(idtorneosRec[i]===infoTorneosDestacados[k][0]){
                                                    encontrado=true;
                                                }
                                            }
    
                                            if(!encontrado && listaRecientes.length<=4){
                                                listaRecientes.push(infoTorneo[j]);
                                            }
                                        }
                                        else if(listaRecientes.length<=4) listaRecientes.push(infoTorneo[j]);
                                    }
                                }
                            }
                        }
    
                        if(listaRecientes.length>0){
                            infoTorneosRecientes=listaRecientes;
                        }
    
                    }
    
    
                }
            }

            setInfoTorneosRecientesUS(infoTorneosRecientes);

        }catch(err){
            reportarError( nomClase, nomFuncion, "", err);
        }         

    }

    const manejarClick = (registro, modOrigen, paramOrigen, modDestino, paramDestino) => {
        const nomFuncion="manejarClick";

        try{
            let vacio=false;
            let encontrado=false;
            let actualizoRec=false;
    
            let torneosRecientes = JSON.parse(localStorage.getItem('torneosRecientes'));
    
            if(torneosRecientes!==null && torneosRecientes!==""){
    
                let idtorneosRec=torneosRecientes.split(",");
    
                if(idtorneosRec.length>0){
                    let i=0;
                    let txtTorneo="";
                    for(i=0;i<idtorneosRec.length;i++){
                        txtTorneo=idtorneosRec[i].replaceAll(":","");
                        if(txtTorneo===infoTorneo[registro][0]){
                            encontrado=true;
                            i=idtorneosRec.length;
                        }
                    }
    
                    if(encontrado){
                        torneosRecientes=torneosRecientes.replaceAll(":" + infoTorneo[registro][0] + ":,","");
                        torneosRecientes=torneosRecientes + ":" + infoTorneo[registro][0] + ":,";
    
                        localStorage.setItem('torneosRecientes', JSON.stringify(torneosRecientes));
                        actualizoRec=true;
                    }
                    else{
                        torneosRecientes=torneosRecientes + ":" + infoTorneo[registro][0] + ":,";
    
                        localStorage.setItem('torneosRecientes', JSON.stringify(torneosRecientes));
                        actualizoRec=true;
                    }
    
                }
                else vacio=true;
            }
            else vacio=true;
    
    
            if(vacio){
                torneosRecientes=":" +  infoTorneo[registro][0] + ":,";
                localStorage.setItem('torneosRecientes', JSON.stringify(torneosRecientes));
                actualizoRec=true;
            }

            if(actualizoRec) procesarRecientes();

        }catch(err){
            reportarError( nomClase, nomFuncion, "", err);
        }
        

        definirModulo(registro, modOrigen, paramOrigen, modDestino, paramDestino);

    }

    const buscarSede = (id) => {
        const nomFuncion="buscarSede";

        let respuesta="";
        try{
            

            let datos=dividirLista(id,"#",2);
            let esEmpresa=false;

            if(datos[0]==="E") esEmpresa=true;
            
            if(infoTorneoO.length>0){
                for(let i=0;i<infoTorneoO.length;i++){
                    if(esEmpresa){
                        if(infoTorneoO[i].idempresa===datos[1]){
                            setEsAccesoLink(true);
                            setNombreEmpresaUS(infoTorneoO[i].empresa);
                            setNombreSedeUS("Todas las Sedes");
                            respuesta=infoTorneoO[i].empresa;
                            i=infoTorneoO.length;                           
                            
                        }
                    } 
                    else{
                        if(infoTorneoO[i].idsede===datos[1]){
                            setEsAccesoLink(true);
                            setNombreEmpresaUS(infoTorneoO[i].empresa);
                            setNombreSedeUS(infoTorneoO[i].sede);
                            respuesta=infoTorneoO[i].sede;
                            i=infoTorneoO.length;
                        }
                    }
                    
                }
            }

        }catch(err){
            reportarError( nomClase, nomFuncion, "", err);
        }
        

        return respuesta;

    }


    const validarCampo = (e, campo, maxlen) => {
        let nomFuncion="validarCampo"; 

        try{
            procesarFiltro=true;
            let newValor = e.target.value;
            if(maxlen!==null && newValor.length>maxlen) newValor=newValor.substring(0,maxlen);

            //let tempArreglo=JSON.parse(JSON.stringify(arrCampos));

            if(campo==="FILTRO"){
                setEsAccesoLink(false);
                cancelarBusqueda=true;
                setFiltro(newValor.toUpperCase());
                setNomSedeUS(newValor);
            } 
            

        }catch(err){
            reportarError( nomClase, nomFuncion, "", err);
        }         
        
    }

    const salir = () => {        
        //definirModulo(0, idModulo, "", modOrigen, paramOrigen);
        definirModulo(0, idModulo, "", ID_MODULO_BASE, "");
    }


    return (
        <>
            <div>
                <div className="CT_encabezado">
                    <div className="CT_elementoEncabezado"  onClick={salir}><img src={ic_salir} className="IM_imgIcono24" alt="" style={{padding: "8px", cursor:"pointer"}} />  </div>
                    <div className="CT_elementoEncabezado"><img className="IM_imgRedondeada36" src={ic_jugadorsinimagen} alt="" /></div>
                    <div className="CT_elementoEncabezado"><p className="TX_unaSolaLinea">{"Espectadores Torneos MarcaGol"}</p></div>
                </div>
                <div style={{display:"flex", background:C_colorPrimaryDark}}>
                    <img src={ic_logo_mg2} alt='' style={{padding: "8px", width:"36px"}} />
                    <div style={{flex:"1"}}>
                        <div style={{fontSize:"18px", color:C_uniformeBlanco, fontWeight:"bold", textAlign:"center"}}>MarcaGol</div>
                        <div style={{fontSize:"14px", color: C_colorAccent , textAlign:"center"}}>Vive Tu Afición Como Un Profesional</div>
                    </div>
                </div>
                <div style={{marginLeft: "10px", marginRight: "10px"}}>
                    <div className="CT_contenedorTitulo">
                        <img className='IM_imgIcono36' height="36px" width="36px" src={ic_torneo_gris} alt='' />   
                        <p style={{marginLeft: "10px", fontSize:"18px"}}>Busca Torneos MarcaGol</p>             
                    </div>

                    {esAccesoLink && (
                        <div style={{textAlign:"left", fontSize:"14px", marginBottom:"10px"}}>
                            <div style={{color: C_colorTextoSinInfo}}>Busqueda realizada con Link de Acceso Directo</div>
                            <div style={{display:"flex", flexWrap:"nowrap"}}>
                                <div style={{width:"120px", fontWeight:"bold"}}>Empresa:</div><div>{nombreEmpresaUS}</div>
                            </div>
                            <div style={{display:"flex", flexWrap:"nowrap"}}>
                                <div style={{width:"120px", fontWeight:"bold"}}>Sede:</div><div>{nombreSedeUS}</div>
                            </div>
                        </div>
                    )}

                    {filtro==="" && (<div style={{marginBlock:"20px", fontSize:"14px", color:C_uniformeVerdeClaro}}>Digite en el cuadro de búsqueda la información de la Empresa organizadora del torneo, la sede donde se juega o el nombre del torneo</div>)}  
                    <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center", margin: "0px"}}>
                        <p style={{marginRight: "10px"}}>Filtro:</p>
                        <input type="text" value={nomSedeUS} className='IP_entradas' style={{flexGrow: 1, flexShrink: 1, flexBasis: "150px"}} placeholder="Empresa/Sede/Nombre Torneo"  onChange={(e) => validarCampo(e, "FILTRO", 80)} />
                    </div>     

                             

                </div>   

                <div className='CT_contenedorFlex'>                

                    {esError===0 && infoTorneosDestacadosUS.length>0 && filtro==="" && (
                        <div className='CT_regSancion'>
                        <div  style={{margin:"10px"}}>
                            <div style={{fontSize:"16px", color:C_colorTextoSinInfo, textAlign:"left", fontWeight:"bold", margin:"10px"}}><span >{"Torneos Destacados"}</span></div>
                        {(infoTorneosDestacadosUS.map((reg, index) => (<DinamicoElementosTorneo key={index} registro={index} numRegistro={index+1} tipoLista={"torneo-destacado"} datos={reg} modOrigen={idModulo} paramOrigen={""} modDestino={ID_MODULO_DETALLE_TORNEO} paramDestino={reg[0]} definirModulo={definirModulo} />))  )}
                        </div>
                        </div>
                    )}

                    {esError===0 && infoTorneosRecientesUS.length>0 && filtro==="" && (
                        <div className='CT_regSancion'>
                        <div  style={{margin:"10px"}}>
                            <div style={{fontSize:"16px", color:C_uniformeVerdeClaro, textAlign:"left", fontWeight:"bold", margin:"10px"}}><span >{"Torneos Recientes"}</span></div>
                        {(infoTorneosRecientesUS.map((reg, index) => (<DinamicoElementosTorneo key={index} registro={index} numRegistro={index+1} tipoLista={"torneo-destacado"} datos={reg} modOrigen={idModulo} paramOrigen={""} modDestino={ID_MODULO_DETALLE_TORNEO} paramDestino={reg[0]} definirModulo={definirModulo} />))  )}
                        </div>
                        </div>
                    )}

                    {esError===0 && filtro!=="" && (
                        datosTorneos.length>0 ? (
                            <div className='CT_regSancion'>
                                <div  style={{margin:"10px"}}>
                                    <div style={{fontSize:"16px", color:C_uniformeVerdeClaro, textAlign:"left", fontWeight:"bold", margin:"10px"}}><span >{"Torneos Encontrados"}</span></div>
                                {(datosTorneos.map((reg, index) => (<DinamicoElementosTorneo key={index} registro={reg[7]} numRegistro={index+1} tipoLista={"torneo-destacado"} datos={reg} modOrigen={idModulo} paramOrigen={paramsAjustado} modDestino={ID_MODULO_DETALLE_TORNEO} paramDestino={reg[0]} definirModulo={manejarClick} />))  )}
                                </div>
                                {limitado && (<div className='TX_sinInfo' style={{marginBlock:"20px"}}>Resultado limitado a 30 torneos, para mayor exactitud, complete o redefina el filtro de búsqueda</div>)}
                            </div>
                        ) :
                        (
                            <div className='CT_regSancion'>
                                <div style={{fontSize:"16px", color:C_uniformeVerdeClaro, textAlign:"center", margin:"10px"}}><span >{"No se encuentran Torneos para el filtro definido"}</span></div>
                            </div>
                        )
                    )}
                

                </div>
            </div>

            {esError!==0 && <ShowError tipoError={esError} funcionCerrar={salir} />}

            {loading && <Loader tipo={"TOTAL"}/>}  
        </>
    );

};

export default Principal_Invitados;