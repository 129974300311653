import { useEffect, useState } from 'react';
import { getDatos } from '../gestionDatos/getDatos';

import {registrarLogEventos, reportarError, presentarError, crearTabla, valorNumero, dividirLista, recuperarDatoLocal, guardarDatoLocal, isFlagUpdate, superaTiempoActualizacion, valorLong, infoGuardadaEsMismoDia, presentarLog, showMensajePantalla} from '../helpers/funciones';
import Loader from '../recVisual/Loader';

import "../estilos/CT_Contenedor.css";
import "../estilos/IM_Imagenes.css";
import "../estilos/TX_Textos.css";
import "../estilos/BF_BordesFondos.css";
import "../estilos/IP_Inputs.css";

import DT_InfoTorneo from '../gestionVisual/DT_InfoTorneo';
import DT_TablaClasificacion from '../gestionVisual/DT_TablaClasificacion';
import DT_InfoPartidos from '../gestionVisual/DT_InfoPartidos';
import DT_InfoSanciones from '../gestionVisual/DT_InfoSanciones';
import DT_InfoEstadisticas from '../gestionVisual/DT_InfoEstadisticas';
import DT_InfoRankingEquipos from '../gestionVisual/DT_InfoRankingEquipos';
import DT_InfoRankingJugadores from '../gestionVisual/DT_InfoRankingJugadores';

import ic_salir from '../recGraficos/ic_salir_w.png'
import ic_torneo_gris from '../recGraficos/ic_torneo_gris_w.png'
import ShowError from '../recVisual/ShowError';
import DT_PresentarFixture from '../gestionVisual/DT_PresentarFixture';
import { ID_MODULO_DETALLE_TORNEO, SEPARADOR_PARAMETROS_BD } from '../constantes/generales';



const ruta=recuperarDatoLocal("rutaRecursos");
const nomClase="Detalle_Torneo_WB";
const idModulo=ID_MODULO_DETALLE_TORNEO;
const subModulo="DTJ";

let idSede="";
let idTorneo="";

let paramsAjustado="";

let escudo=ic_torneo_gris;
let publicidad="";
let idSemanaActual="";
let idJornadaActual="";
let numPartidos=0;
let tipo_registroinfo="1";
let tiempo_antes="0";

let tipoLista="fecha";
let idJornadaSel="";

let tieneTarjetaAzul=false;

let yaConsultoInfoJugadores=false;

let titulo="";
let mensaje="";
let ventanaMensaje;

let columnasASumar=[6,7,8,9,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,33,34,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61];
let columnasOrdenar=[0,1,1,1,1,1,1,1,1,1,1,1,1,1,0,0,0,0,0,0,0,0,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,0,1,1,1];
//let columnasOrdenarJug=[3,4,5,6,7,8,9,10,11,12,13,14,15,16,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,42,43,44];

let columnasSuma=[];
let columnasAvg=[];
let infoOrdenadaEquipos=[];

let hayPartidosFE=false;


let infoTorneoO = [];
let infoTorneo = [];
function crearInfoTorneo(datos){   
    const nomFuncion="crearInfoTorneo";

    try{
        let torneo = {
            empresa:"",
            sede:"",
            nombre:"",
            descripcion:"",
            horario:"",
            cantJornadas:"",
            fechaInicio:"",
            cantJugadores:"",
            parametros:"",
            cantPartidos:"",
            idSede:"",
            reglamento:"",
            publicidad:"",
            tipo_torneo:"",
            tipo_registroinfo:"",
            escudo:"",
            infoCalificacion:"",
            incluir_opciones:"",
            minutos_antes:"",
            permite_empate:"",
            puntos_desempate:"",
            puntos_ganador:"",
            tiene_tarjeta_azul:"",
            activo:"",
            idempresa:""
        }
    
        infoTorneoO = [];
        infoTorneo = [];
    
        let i, j;
        for(i=0;i<datos.length;i++){
            let registro=Object.assign({} , torneo);
            for(j=0;j<datos[i].length;j++){
                if(j===0) registro.empresa=datos[i][j];
                if(j===1) registro.sede=datos[i][j];
                if(j===2) registro.nombre=datos[i][j];
                if(j===3) registro.descripcion=datos[i][j];
                if(j===4) registro.horario=datos[i][j];
                if(j===5) registro.cantJornadas=datos[i][j];
                if(j===6) registro.fechaInicio=datos[i][j];
                if(j===7) registro.cantJugadores=datos[i][j];
                if(j===8) registro.parametros=datos[i][j];
                if(j===9) registro.cantPartidos=datos[i][j];
                if(j===10) registro.idSede=datos[i][j];
                if(j===11) registro.reglamento=datos[i][j];
                if(j===12) registro.publicidad=datos[i][j];
                if(j===13) registro.tipo_torneo=datos[i][j];
                if(j===14) registro.tipo_registroinfo=datos[i][j];
                if(j===15) registro.escudo=datos[i][j];
                if(j===16) registro.infoCalificacion=datos[i][j];
                if(j===17) registro.incluir_opciones=datos[i][j];
                if(j===18) registro.minutos_antes=datos[i][j];
                if(j===19) registro.permite_empate=datos[i][j];
                if(j===20) registro.puntos_desempate=datos[i][j];
                if(j===21) registro.puntos_ganador=datos[i][j];
                if(j===22) registro.tiene_tarjeta_azul=datos[i][j];
                if(j===23) registro.activo=datos[i][j];
                if(j===24) registro.idempresa=datos[i][j];
            }
            if(i===0){
                if(registro.escudo!=="") escudo=ruta + registro.escudo;
                else escudo=ic_torneo_gris;
                publicidad= registro.publicidad;
                tipo_registroinfo = registro.tipo_registroinfo;
                idSede=registro.idSede;
                guardarDatoLocal("idSede",idSede);
                tiempo_antes=registro.minutos_antes;
                
                if(registro.tiene_tarjeta_azul==="1") tieneTarjetaAzul=true;
                else tieneTarjetaAzul=false;
            } 
            infoTorneoO.push(registro);
            infoTorneo.push(datos[i]);
        }

    }catch(err){
        reportarError(nomClase, nomFuncion, "", err);
    }
    
}

let infoClasificacionO = [];
let infoClasificacion = [];
function crearInfoClasificacion(datos){    
    const nomFuncion="crearInfoClasificacion";

    try{
        const clasificacion = {
            idtorneo:"",
            sinInfo:"",
            idEquipo:"",
            nombrecorto:"",
            escudo:"",
            posicion:"",
            partidosjugados:"",
            golesafavor:"",
            golesencontra:"",
            goldiferencia:"",
            puntos:"",
            faltasrecibidas:"",
            faltascometidas:"",
            tarjetasamarillas:"",
            tarjetasrojas:"",
            fairplay:"",
            atajadas:"",
            disparosarco:"",
            disparosdentro:"",
            autogoles:"",
            asistencias:"",
            golesjugada:"",
            golestirolibre:"",
            golespenalti:"",
            promediojugadores:"",
            promediocambios:"",
            tiempojugadot1:"",
            tiempojugadot2:"",
            tiempojugadototal:"",
            efectividadGoles:"",
            efectividadAtajadas:"",
            tc_posicion:"",
            tc_partidosjugados:"",
            tc_golesafavor:"",
            tc_golesencontra:"",
            tc_goldiferencia:"",
            tc_puntos:"",
            tc_numgrupo:"",
            titulo_equipo:"",
            puntos_barra:"",
            faltascometidasFG:"",
            tarjetasamarillasFG:"",
            tarjetasrojasFG:"",
            fairplayFG:"",
            atajadasFG:"",
            disparosarcoFG:"",
            disparosdentroFG:"",
            autogolesFG:"",
            golesafavorFE:"",
            golesencontraFE:"",
            faltascometidasFE:"",
            tarjetasamarillasFE:"",
            tarjetasrojasFE:"",
            fairplayFE:"",
            atajadasFE:"",
            disparosarcoFE:"",
            disparosdentroFE:"",
            autogolesFE:"",
            partidosjugadosFE:"",
            tarjetasazulesFE:"",
            tarjetasazules:"",
            tarjetasazulesFG:""
        }
    
        infoClasificacionO = [];
        infoClasificacion = [];
    
        let i, j;
        let grupoAnt="";
        for(i=0;i<datos.length;i++){
            let registro=Object.assign({} , clasificacion);
            let temp="";
            
            if(datos[i][37]!==grupoAnt){
                temp="1";
                grupoAnt=datos[i][37];
            } 
            for(j=0;j<datos[i].length;j++){
                if(j===0) registro.idtorneo=datos[i][j];
                if(j===1) registro.sinInfo=temp;
                if(j===2) registro.idEquipo=datos[i][j];
                if(j===3) registro.nombrecorto=datos[i][j];
                if(j===4) registro.escudo=datos[i][j];
                if(j===5) registro.posicion=datos[i][j];
                if(j===6) registro.partidosjugados=datos[i][j];
                if(j===7) registro.golesafavor=datos[i][j];
                if(j===8) registro.golesencontra=datos[i][j];
                if(j===9) registro.goldiferencia=datos[i][j];
                if(j===10) registro.puntos=datos[i][j];
                if(j===11) registro.faltasrecibidas=datos[i][j];
                if(j===12) registro.faltascometidas=datos[i][j];
                if(j===13) registro.tarjetasamarillas=datos[i][j];
                if(j===14) registro.tarjetasrojas=datos[i][j];
                if(j===15) registro.fairplay=datos[i][j];
                if(j===16) registro.atajadas=datos[i][j];
                if(j===17) registro.disparosarco=datos[i][j];
                if(j===18) registro.disparosdentro=datos[i][j];
                if(j===19) registro.autogoles=datos[i][j];
                if(j===20) registro.asistencias=datos[i][j];
                if(j===21) registro.golesjugada=datos[i][j];
                if(j===22) registro.golestirolibre=datos[i][j];
                if(j===23) registro.golespenalti=datos[i][j];
                if(j===24) registro.promediojugadores=datos[i][j];
                if(j===25) registro.promediocambios=datos[i][j];
                if(j===26) registro.tiempojugadot1=datos[i][j];
                if(j===27) registro.tiempojugadot2=datos[i][j];
                if(j===28) registro.tiempojugadototal=datos[i][j];
                if(j===29) registro.efectividadGoles=datos[i][j];
                if(j===30) registro.efectividadAtajadas=datos[i][j];
                if(j===31) registro.tc_posicion=datos[i][j];
                if(j===32) registro.tc_partidosjugados=datos[i][j];
                if(j===33) registro.tc_golesafavor=datos[i][j];
                if(j===34) registro.tc_golesencontra=datos[i][j];
                if(j===35) registro.tc_goldiferencia=datos[i][j];
                if(j===36) registro.tc_puntos=datos[i][j];
                if(j===37) registro.tc_numgrupo=datos[i][j];
                if(j===38) registro.titulo_equipo=datos[i][j];
                if(j===39) registro.puntos_barra=datos[i][j];
                if(j===40) registro.faltascometidasFG=datos[i][j];
                if(j===41) registro.tarjetasamarillasFG=datos[i][j];
                if(j===42) registro.tarjetasrojasFG=datos[i][j];
                if(j===43) registro.fairplayFG=datos[i][j];
                if(j===44) registro.atajadasFG=datos[i][j];
                if(j===45) registro.disparosarcoFG=datos[i][j];
                if(j===46) registro.disparosdentroFG=datos[i][j];
                if(j===47) registro.autogolesFG=datos[i][j];
                if(j===48) registro.golesafavorFE= "" + (valorNumero(datos[i][7]) - valorNumero(datos[i][33]));
                if(j===49) registro.golesencontraFE= "" + (valorNumero(datos[i][8]) - valorNumero(datos[i][34]));
                if(j===50) registro.faltascometidasFE= "" + (valorNumero(datos[i][12]) - valorNumero(datos[i][40]));
                if(j===51) registro.tarjetasamarillasFE= "" + (valorNumero(datos[i][13]) - valorNumero(datos[i][41]));
                if(j===52) registro.tarjetasrojasFE= "" + (valorNumero(datos[i][14]) - valorNumero(datos[i][42]));
                if(j===53) registro.fairplayFE= "" + (valorNumero(datos[i][15]) - valorNumero(datos[i][43]));
                if(j===54) registro.atajadasFE= "" + (valorNumero(datos[i][16]) - valorNumero(datos[i][44]));
                if(j===55) registro.disparosarcoFE= "" + (valorNumero(datos[i][17]) - valorNumero(datos[i][45]));
                if(j===56) registro.disparosdentroFE= "" + (valorNumero(datos[i][18]) - valorNumero(datos[i][46]));
                if(j===57) registro.autogolesFE= "" + (valorNumero(datos[i][19]) - valorNumero(datos[i][47]));
                if(j===58) registro.partidosjugadosFE= "" + (valorNumero(datos[i][6]) - valorNumero(datos[i][32]));
                if(j===60) registro.tarjetasazules=datos[i][j];
                if(j===61){
                    registro.tarjetasazulesFG=datos[i][j];
                    registro.tarjetasazulesFE= "" + (valorNumero(datos[i][60]) - valorNumero(datos[i][61]));
                } 
            }

            let datosTemp = [];
            for(j=0;j<datos[i].length;j++){
                if(j<=47 || j>=60) datosTemp.push(datos[i][j]);

                if(j===48) datosTemp.push(registro.golesafavorFE);
                if(j===49) datosTemp.push(registro.golesencontraFE);
                if(j===50) datosTemp.push(registro.faltascometidasFE);
                if(j===51) datosTemp.push(registro.tarjetasamarillasFE);
                if(j===52) datosTemp.push(registro.tarjetasrojasFE);
                if(j===53) datosTemp.push(registro.fairplayFE);
                if(j===54) datosTemp.push(registro.atajadasFE);
                if(j===55) datosTemp.push(registro.disparosarcoFE);
                if(j===56) datosTemp.push(registro.disparosdentroFE);
                if(j===57) datosTemp.push(registro.autogolesFE);
                if(j===58) datosTemp.push(registro.partidosjugadosFE);
                if(j===59) datosTemp.push(registro.tarjetasazulesFE);
            }

            infoClasificacionO.push(registro);
            infoClasificacion.push(datosTemp);

            
        }


    }catch(err){
        reportarError(nomClase, nomFuncion, "", err);
    }

}

let infoFechas = [];
let infoJornadas = [];
let infoPartidosProgramadosO = [];
let infoPartidosProgramados = [];
let partidosEliminacionOrdenados = [];
function crearPartidosProgramados(datos){    
    const nomFuncion="crearPartidosProgramados";

    try{
        const partidosProgramados = {
            idTorneo:"",
            nomTorneo:"",
            idPartido:"",
            numJornada:"",
            numCancha:"",
            idEquipo1:"",
            nomCortoEq1:"",
            imgEscudoEq1:"",
            idEquipo2:"",
            nomCortoEq2:"",
            imgEscudoEq2:"",
            nomArbitro1:"",
            nomArbitro2:"",
            fechaPartido:"",
            horaPartido:"",
            golesEq1:"",
            golesEq2:"",
            parte:"",
            tiempoActual:"",
            tiempoTotal:"",
            idEstado:"",
            nomEstado:"",
            fechaActual:"",
            horaActual:"",
            idJornada:"",
            estadoTiempo:"",
            fechaInicioTiempo:"",
            confirmoAsistencia:"",
            fechaConfirmo:"",
            tipoTorneo:"",
            tipoRegistroInfo:"",
            partidoActivo:"",
            idSede:"",
            idEstadoInfArbitro:"",
            imgArbitro1:"",
            calificacionPartido:"",
            penaltisEq1:"",
            penaltisEq2:"",
            numNuevoJornada:"",
            nomJornada:"",
            semFechainicial:"",
            semFechaFinal:"",
            esSemanaActual:"",
            resumenJornada:"",
            tipoJornada2:"",
            nomJornada2:"",
            idsemana:"",
            numsemana:"",
            semFechainicial2:"",
            semFechaFinal2:"",
            tipoJornadaEliminacion:"",
            idcopa:"",
            nombreCopa:"",
            ordenCopa:"",
            fixturePartido:"",
            fixtureOrden:"",
            nombreCancha:""
        }   
    
        infoFechas = [];
        infoJornadas = [];
        infoPartidosProgramadosO = [];
        infoPartidosProgramados = [];
        hayPartidosFE=false;

    
        let i, j;
        let tempPartidos=0;
        for(i=0;i<datos.length;i++){
            let registro=Object.assign({} , partidosProgramados);
            for(j=0;j<datos[i].length;j++){
                if(j===0) registro.idTorneo=datos[i][j];
                if(j===1) registro.nomTorneo=datos[i][j];
                if(j===2) registro.idPartido=datos[i][j];
                if(j===3) registro.numJornada=datos[i][j];
                if(j===4) registro.numCancha=datos[i][j];
                if(j===5) registro.idEquipo1=datos[i][j];
                if(j===6) registro.nomCortoEq1=datos[i][j];
                if(j===7) registro.imgEscudoEq1=datos[i][j];
                if(j===8) registro.idEquipo2=datos[i][j];
                if(j===9) registro.nomCortoEq2=datos[i][j];
                if(j===10) registro.imgEscudoEq2=datos[i][j];
                if(j===11) registro.nomArbitro1=datos[i][j];
                if(j===12) registro.nomArbitro2=datos[i][j];
                if(j===13) registro.fechaPartido=datos[i][j];
                if(j===14) registro.horaPartido=datos[i][j];
                if(j===15) registro.golesEq1=datos[i][j];
                if(j===16) registro.golesEq2=datos[i][j];
                if(j===17) registro.parte=datos[i][j];
                if(j===18) registro.tiempoActual=datos[i][j];
                if(j===19) registro.tiempoTotal=datos[i][j];
                if(j===20) registro.idEstado=datos[i][j];
                if(j===21) registro.nomEstado=datos[i][j];
                if(j===22) registro.fechaActual=datos[i][j];
                if(j===23) registro.horaActual=datos[i][j];
                if(j===24) registro.idJornada=datos[i][j];
                if(j===25) registro.estadoTiempo=datos[i][j];
                if(j===26) registro.fechaInicioTiempo=datos[i][j];
                if(j===27) registro.confirmoAsistencia=datos[i][j];
                if(j===28) registro.fechaConfirmo=datos[i][j];
                if(j===29) registro.tipoTorneo=datos[i][j];
                if(j===30) registro.tipoRegistroInfo=datos[i][j];
                if(j===31) registro.partidoActivo=datos[i][j];
                if(j===32) registro.idSede=datos[i][j];
                if(j===33) registro.idEstadoInfArbitro=datos[i][j];
                if(j===34) registro.imgArbitro1=datos[i][j];
                if(j===35) registro.calificacionPartido=datos[i][j];
                if(j===36) registro.penaltisEq1=datos[i][j];
                if(j===37) registro.penaltisEq2=datos[i][j];
                if(j===38) registro.numNuevoJornada=datos[i][j];
                if(j===39) registro.nomJornada=datos[i][j];
                if(j===40) registro.semFechainicial=datos[i][j];
                if(j===41) registro.semFechaFinal=datos[i][j];
                if(j===42) registro.esSemanaActual=datos[i][j];
                if(j===43) registro.resumenJornada=datos[i][j];
                if(j===44) registro.tipoJornada2=datos[i][j];
                if(j===45) registro.nomJornada2=datos[i][j];
                if(j===46) registro.idsemana=datos[i][j];
                if(j===47) registro.numsemana=datos[i][j];
                if(j===48) registro.semFechainicial2=datos[i][j];
                if(j===49) registro.semFechaFinal2=datos[i][j];
                if(j===50) registro.tipoJornadaEliminacion=datos[i][j];
                if(j===51) registro.idcopa=datos[i][j];
                if(j===52) registro.nombreCopa=datos[i][j];
                if(j===53) registro.ordenCopa=datos[i][j];
                if(j===54) registro.fixturePartido=datos[i][j];
                if(j===55) registro.fixtureOrden=datos[i][j];
                if(j===56) registro.nombreCancha=datos[i][j];
            }
            infoPartidosProgramadosO.push(registro);
            infoPartidosProgramados.push(datos[i]);
    
            if(registro.idEstado==="5" || registro.idEstado==="9" || registro.idEstado==="10"){
                tempPartidos++;
            }

            if(registro.tipoJornada2==="2"){
                hayPartidosFE=true;
            } 
    
        }
    
        numPartidos=tempPartidos;
        crearListaFechas();
        crearListaJornadas();

        


    }catch(err){
        reportarError(nomClase, nomFuncion, "", err);
    }    

}

let infoJugadoresO = [];
let infoJugadores = [];
//var infoOrdenadaJugadores = [];
function crearInfoJugadores(datos){   
    const nomFuncion="crearInfoJugadores";

    try{
        let jugador = {
            idjugador:"",
            nombre:"",
            nomEquipo:"",
            pjugados:"",
            goles:"",
            autogoles:"",
            asistencias:"",
            atajadas:"",
            disparosarco:"",
            disparosdentro:"",
            faltasrecibidas:"",
            faltascometidas:"",
            tarjetasamarillas:"",
            tarjetasrojas:"",
            tiempoJugado:"",
            golesAsistencias:"",
            efectividadGoles:"",
            foto:"",
            pjugadosFG:"",
            golesFG:"",
            autogolesFG:"",
            asistenciasFG:"",
            atajadasFG:"",
            disparosarcoFG:"",
            disparosdentroFG:"",
            faltasrecibidasFG:"",
            faltascometidasFG:"",
            tarjetasamarillasFG:"",
            tarjetasrojasFG:"",
            golesAsistenciasFG:"",
            golesFE:"",
            autogolesFE:"",
            asistenciasFE:"",
            atajadasFE:"",
            disparosarcoFE:"",
            disparosdentroFE:"",
            faltasrecibidasFE:"",
            faltascometidasFE:"",
            tarjetasamarillasFE:"",
            tarjetasrojasFE:"",
            golesAsistenciasFE:"",
            pjugadosFE:"",
            tarjetasazulesFE:"",
            tarjetasazules:"",
            tarjetasazulesFG:"",
            idequipo:""
        }
    
        infoJugadoresO = [];
        infoJugadores = [];
    
        let i, j;
        for(i=0;i<datos.length;i++){
            let registro=Object.assign({} , jugador);
            for(j=0;j<datos[i].length;j++){
                if(j===0) registro.idjugador=datos[i][j];
                if(j===1) registro.nombre=datos[i][j];
                if(j===2) registro.nomEquipo=datos[i][j];
                if(j===3) registro.pjugados=datos[i][j];
                if(j===4) registro.goles=datos[i][j];
                if(j===5) registro.autogoles=datos[i][j];
                if(j===6) registro.asistencias=datos[i][j];
                if(j===7) registro.atajadas=datos[i][j];
                if(j===8) registro.disparosarco=datos[i][j];
                if(j===9) registro.disparosdentro=datos[i][j];
                if(j===10) registro.faltasrecibidas=datos[i][j];
                if(j===11) registro.faltascometidas=datos[i][j];
                if(j===12) registro.tarjetasamarillas=datos[i][j];
                if(j===13) registro.tarjetasrojas=datos[i][j];
                if(j===14) registro.tiempoJugado=datos[i][j];
                if(j===15) registro.golesAsistencias=datos[i][j];
                if(j===16) registro.efectividadGoles=datos[i][j];
                if(j===17) registro.foto=datos[i][j];
                if(j===18) registro.pjugadosFG=datos[i][j];
                if(j===19) registro.golesFG=datos[i][j];
                if(j===20) registro.autogolesFG=datos[i][j];
                if(j===21) registro.asistenciasFG=datos[i][j];
                if(j===22) registro.atajadasFG=datos[i][j];
                if(j===23) registro.disparosarcoFG=datos[i][j];
                if(j===24) registro.disparosdentroFG=datos[i][j];
                if(j===25) registro.faltasrecibidasFG=datos[i][j];
                if(j===26) registro.faltascometidasFG=datos[i][j];
                if(j===27) registro.tarjetasamarillasFG=datos[i][j];
                if(j===28) registro.tarjetasrojasFG=datos[i][j];
                if(j===29) registro.golesAsistenciasFG= "" + (valorNumero(datos[i][19]) - valorNumero(datos[i][21]));
                if(j===30) registro.golesFE= "" + (valorNumero(datos[i][4]) - valorNumero(datos[i][19]));
                if(j===31) registro.autogolesFE= "" + (valorNumero(datos[i][5]) - valorNumero(datos[i][20]));
                if(j===32) registro.asistenciasFE= "" + (valorNumero(datos[i][6]) - valorNumero(datos[i][21]));
                if(j===33) registro.atajadasFE= "" + (valorNumero(datos[i][7]) - valorNumero(datos[i][22]));
                if(j===34) registro.disparosarcoFE= "" + (valorNumero(datos[i][8]) - valorNumero(datos[i][23]));
                if(j===35) registro.disparosdentroFE= "" + (valorNumero(datos[i][9]) - valorNumero(datos[i][24]));
                if(j===36) registro.faltasrecibidasFE= "" + (valorNumero(datos[i][10]) - valorNumero(datos[i][25]));
                if(j===37) registro.faltascometidasFE= "" + (valorNumero(datos[i][11]) - valorNumero(datos[i][26]));
                if(j===38) registro.tarjetasamarillasFE= "" + (valorNumero(datos[i][12]) - valorNumero(datos[i][27]));
                if(j===39) registro.tarjetasrojasFE= "" + (valorNumero(datos[i][13]) - valorNumero(datos[i][28]));
                if(j===40) registro.golesAsistenciasFE= "" + (valorNumero(registro.golesAsistencias) - valorNumero(registro.golesAsistenciasFG));
                if(j===41) registro.pjugadosFE= "" + (valorNumero(registro.pjugados) - valorNumero(registro.pjugadosFG));
                if(j===43) registro.tarjetasazules=datos[i][j];
                if(j===44){
                    registro.tarjetasazulesFG=datos[i][j];
                    registro.tarjetasazulesFE="" + (valorNumero(datos[i][43]) - valorNumero(datos[i][44]));
                }
                if(j===45) registro.idequipo=datos[i][j];
            }

            let datosTemp = [];
            for(j=0;j<datos[i].length;j++){
                if(j<=28 || j>=43) datosTemp.push(datos[i][j]);

                if(j===29) datosTemp.push(registro.golesAsistenciasFG);
                if(j===30) datosTemp.push(registro.golesFE);
                if(j===31) datosTemp.push(registro.autogolesFE);
                if(j===32) datosTemp.push(registro.asistenciasFE);
                if(j===33) datosTemp.push(registro.atajadasFE);
                if(j===34) datosTemp.push(registro.disparosarcoFE);
                if(j===35) datosTemp.push(registro.disparosdentroFE);
                if(j===36) datosTemp.push(registro.faltasrecibidasFE);
                if(j===37) datosTemp.push(registro.faltascometidasFE);
                if(j===38) datosTemp.push(registro.tarjetasamarillasFE);
                if(j===39) datosTemp.push(registro.tarjetasrojasFE);
                if(j===40) datosTemp.push(registro.golesAsistenciasFE);
                if(j===41) datosTemp.push(registro.pjugadosFE);
                if(j===42) datosTemp.push(registro.tarjetasazulesFE);
            }

            infoJugadoresO.push(registro);
            infoJugadores.push(datosTemp);


        }

    }catch(err){
        reportarError(nomClase, nomFuncion, "", err);
    }      

}

let infoSancionesO = [];
let infoSanciones = [];
function crearInfoSanciones(datos){    
    const nomFuncion="crearInfoSanciones";

    try{
        let sancion = {
            idtorneo:"",
            idpartido:"",
            tipoSancion:"",
            nomSancion:"",
            totalpartidos:"",
            valSancion:"",
            txtsancion:"",
            fecha_sancion:"",
            escudoEq1:"",
            nomEquipo1:"",
            escudoEq2:"",
            nomEquipo2:"",
            jornada:"",
            fechaPartido:"",
            horaPartido:"",
            idjugador:"",
            nomJugador:"",
            fotoJugador:"",
            nomEquipo:""
        }
    
        infoSancionesO = [];
        infoSanciones = [];
    
        let i, j;
        for(i=0;i<datos.length;i++){
            let registro=Object.assign({} , sancion);
            for(j=0;j<datos[i].length;j++){
                if(j===0) registro.idtorneo=datos[i][j];
                if(j===1) registro.idpartido=datos[i][j];
                if(j===2) registro.tipoSancion=datos[i][j];
                if(j===3) registro.nomSancion=datos[i][j];
                if(j===4) registro.totalpartidos=datos[i][j];
                if(j===5) registro.valSancion=datos[i][j];
                if(j===6) registro.txtsancion=datos[i][j];
                if(j===7) registro.fecha_sancion=datos[i][j];
                if(j===8) registro.escudoEq1=datos[i][j];
                if(j===9) registro.nomEquipo1=datos[i][j];
                if(j===10) registro.escudoEq2=datos[i][j];
                if(j===11) registro.nomEquipo2=datos[i][j];
                if(j===12) registro.jornada=datos[i][j];
                if(j===13) registro.fechaPartido=datos[i][j];
                if(j===14) registro.horaPartido=datos[i][j];
                if(j===15) registro.idjugador=datos[i][j];
                if(j===16) registro.nomJugador=datos[i][j];
                if(j===17) registro.fotoJugador=datos[i][j];
                if(j===18) registro.nomEquipo=datos[i][j];
            }
            infoSancionesO.push(registro);
            infoSanciones.push(datos[i]);
        }
    }catch(err){
        reportarError(nomClase, nomFuncion, "", err);
    }     

}

let infoGruposFEO = [];
let infoGruposFE = [];
function crearInfoGruposFE(datos){    
    const nomFuncion="crearInfoGruposFE";

    try{
        let grupo = {
            idjornada:"",
            idcopa:"",
            idequipo:"",
            numGrupo:"",
            numJornada:""
        }
    
        infoGruposFEO = [];
        infoGruposFE = [];
    
        let i, j;
        for(i=0;i<datos.length;i++){
            let registro=Object.assign({} , grupo);
            for(j=0;j<datos[i].length;j++){
                if(j===0) registro.idjornada=datos[i][j];
                if(j===1) registro.idcopa=datos[i][j];
                if(j===2) registro.idequipo=datos[i][j];
                if(j===3) registro.numGrupo=datos[i][j];
                if(j===4) registro.numJornada=datos[i][j];
            }
            infoGruposFEO.push(registro);
            infoGruposFE.push(datos[i]);
        }
    }catch(err){
        reportarError(nomClase, nomFuncion, "", err);
    } 

    
}

function crearListaFechas(){
    const nomFuncion="crearListaFechas";

    try{
        //infoFechasTI=false;
        let tempFechas=[];
        infoFechas=[];

        if(infoPartidosProgramadosO.length>0){
            let i,j;
            for(i=0;i<infoPartidosProgramadosO.length;i++){
                if(idSemanaActual===""){
                    if(infoPartidosProgramadosO[i].esSemanaActual==="1"){
                        idSemanaActual=infoPartidosProgramadosO[i].idsemana;
                    }
                }

                let encontrado=false;
                for(j=0;j<tempFechas.length;j++){
                    if(infoPartidosProgramadosO[i].idsemana===tempFechas[j][0]){
                        encontrado=true;
                        j=tempFechas.length;
                    }
                }

                if(!encontrado){
                    let tempFechaPartido=[];

                    tempFechaPartido[0]=infoPartidosProgramadosO[i].idsemana;
                    tempFechaPartido[1]=infoPartidosProgramadosO[i].numsemana;
                    tempFechaPartido[2]=infoPartidosProgramadosO[i].semFechainicial2;
                    tempFechaPartido[3]=infoPartidosProgramadosO[i].semFechaFinal2;

                    tempFechas.push(tempFechaPartido);
                }

            }

            if(tempFechas.length>0){
                let fechasOrdenadas=[];
                for(i=0;i<tempFechas.length;i++){
                    let numSemanaA=valorNumero(tempFechas[i][0]);
                    let posicion=0;
                    for(j=0;j<fechasOrdenadas.length;j++){
                        let numSemanaB=valorNumero(fechasOrdenadas[j][0]);

                        //posicion=j;
                        if(numSemanaA<numSemanaB){
                            posicion=j;
                            j=fechasOrdenadas.size();
                        }
                        else posicion=j+1;
                    }
                    fechasOrdenadas.splice(posicion,0, tempFechas[i]);
                }

                //infoFechas=new String[fechasOrdenadas.size()][4];
                for(i=0;i<fechasOrdenadas.length;i++){
                    infoFechas.push(fechasOrdenadas[i]);
                }

                if(idSemanaActual==="" && infoFechas.length>0) idSemanaActual= infoFechas[infoFechas.length-1][0];

                //infoFechasTI=true;
            }
        }


    }catch(err){
        reportarError(nomClase, nomFuncion, "", err);
    }

}

function crearListaJornadas(){
    const nomFuncion="crearListaJornadas";

    try{
        let tempJornadas=[];
        infoJornadas=[];

        if(infoPartidosProgramadosO.length>0){
            let i,j;
            for(i=0;i<infoPartidosProgramadosO.length;i++){
                let encontrado=false;
                for(j=0;j<tempJornadas.length;j++){
                    if(infoPartidosProgramadosO[i].idJornada===tempJornadas[j][0]){
                        encontrado=true;
                        j=tempJornadas.length;
                    }
                }

                if(!encontrado){
                    let tempJornadasPartido=[];

                    tempJornadasPartido[0]=infoPartidosProgramadosO[i].idJornada;
                    tempJornadasPartido[1]=infoPartidosProgramadosO[i].numNuevoJornada;
                    tempJornadasPartido[2]=infoPartidosProgramadosO[i].tipoJornada2;
                    tempJornadasPartido[3]=infoPartidosProgramadosO[i].nomJornada;
                    tempJornadasPartido[4]=infoPartidosProgramadosO[i].nombreCopa;
                    tempJornadasPartido[5]=infoPartidosProgramadosO[i].tipoJornadaEliminacion;
                    tempJornadasPartido[6]=infoPartidosProgramadosO[i].ordenCopa;

                    tempJornadas.push(tempJornadasPartido);
                }

            }


            if(tempJornadas.length>0){
                let jornadasOrdenadas=[];
                for(i=0;i<tempJornadas.length;i++){
                    let tipoJornadaA=valorNumero(tempJornadas[i][2]);
                    let posicion=0;
                    for(j=0;j<jornadasOrdenadas.length;j++){
                        let tipoJornadaB=valorNumero(jornadasOrdenadas[j][2]);

                        //posicion=j;
                        if(tipoJornadaA<tipoJornadaB){
                            posicion=j;
                            j=jornadasOrdenadas.length;
                        }
                        else{
                            if(tipoJornadaA===tipoJornadaB){
                                if(tipoJornadaA==="1"){
                                    let numJornadaA=valorNumero(tempJornadas[i][1]);
                                    let numJornadaB=valorNumero(jornadasOrdenadas[j][1]);

                                    if(numJornadaA<numJornadaB){
                                        posicion=j;
                                        j=jornadasOrdenadas.length;
                                    }
                                    else posicion=j+1;
                                }
                                else{
                                    let ordenCopaA=valorNumero(tempJornadas[i][6]);
                                    let ordenCopaB=valorNumero(jornadasOrdenadas[j][6]);

                                    if(ordenCopaA<ordenCopaB){
                                        posicion=j;
                                        j=jornadasOrdenadas.length;
                                    }
                                    else {
                                        if(ordenCopaA===ordenCopaB){
                                            let numJornadaA=valorNumero(tempJornadas[i][1]);
                                            let numJornadaB=valorNumero(jornadasOrdenadas[j][1]);
    
                                            if(numJornadaA<numJornadaB){
                                                posicion=j;
                                                j=jornadasOrdenadas.length;
                                            }
                                            else posicion=j+1;
                                        }
                                        else posicion=j+1;                                        
                                    }
                                }
                            }
                            else{
                                posicion=j+1;
                            }            


                        } 
                    }
                    jornadasOrdenadas.splice(posicion,0, tempJornadas[i]);
                }


                infoJornadas=jornadasOrdenadas;
                let regJornadaSel=0;
                let seleccionada=false;
                for(i=0;i<infoJornadas.length;i++){
                    //infoJornadas[i]=jornadasOrdenadas[i];

                    if(tipoLista==="jornada" && idJornadaSel!==""){
                        if(idJornadaSel===infoJornadas[i][0]) {
                            regJornadaSel=i;
                            i=infoJornadas.length;
                        }
                    }
                    else{
                        if(infoJornadas[i][2]==="1") regJornadaSel=i;
                        else{
                            if(!seleccionada){
                                seleccionada=true;
                                regJornadaSel=i;
                            } 
                            else{   
                                if(infoJornadas[regJornadaSel][6]===infoJornadas[i][6]){
                                    let numJorA=valorNumero(infoJornadas[regJornadaSel][1]);
                                    let numJorB=valorNumero(infoJornadas[i][1]);
    
                                    if(numJorA<numJorB) regJornadaSel=i;
                                }
                            }
                            
                        }
                    }
                    
                }

                idJornadaActual=infoJornadas[regJornadaSel][0];

            }


        }


    }catch(err){
        reportarError(nomClase, nomFuncion, "", err);
    }
}


const ordenarInfomacion = () => {
    const nomFuncion="ordenarInfomacion";

    try{
        let i,j;
        let suma=0;
        let contTieneInfo=0;
        let promedio=0;

        columnasSuma=[];
        columnasAvg=[];
        infoOrdenadaEquipos=[];
        //hayPartidosFE=false;

        
        if(infoClasificacion.length>0){

            for(i=0;i<columnasASumar.length;i++){
                let col=columnasASumar[i];
                let datosTemp=[]; 
                suma=0;
                promedio=0;
                contTieneInfo=0;
                let partJugados=0;
                for(j=0;j<infoClasificacion.length;j++){
                    datosTemp.push(valorNumero(infoClasificacion[j][col]));
                    suma=suma+datosTemp[j];
                    partJugados=valorNumero(infoClasificacion[j][6]);
                    if(partJugados>0) contTieneInfo++;
                }
                if(contTieneInfo>0) promedio=(suma/contTieneInfo);
                columnasSuma.push(suma);
                columnasAvg.push(promedio);

                if(columnasOrdenar[i]===1){
                    let datosTemp2=organizarLista(datosTemp);

                    /*for(j=0;j<datosTemp2.length;j++){
                        infoOrdenadaEquipos[j][i]=datosTemp2[j];
                    }*/
                    
                    infoOrdenadaEquipos.push(datosTemp2);
                }
                else{
                    /*for(j=0;j<datosTemp.length;j++){
                        infoOrdenadaEquipos[j][i]=datosTemp[j];
                    }*/

                    infoOrdenadaEquipos.push(datosTemp);
                } 

            }

            

            //if(columnasSuma.length>44 && columnasSuma[44]>0) hayPartidosFE=true;

        }

    }catch(err){
        reportarError( nomClase, nomFuncion, "", err);
    }

}


const organizarLista = (list) => {
    const nomFuncion="organizarLista";
    let posiciones=[]; //new int[list.length];

    try{
        let k, i, j;
        for(k=0;k<list.length;k++){
            posiciones.push(k);
        }

        for(i =0; i< list.length; i++){
            let sorted = true; // asumo que para la iteración i el listado es ordenado,
            for(j =0; j< list.length - i - 1; j++){ // en cada iteración los elementos desde la posición (length-i) estan ordenados, por lo tanto solo recorro hasta esa posición
                if(list[j] < list[j+1]){
                    let temp = list[j];
                    list[j] = list[j+1];
                    list[j+1] = temp;
                    sorted = false;

                    temp=posiciones[j];
                    posiciones[j]=posiciones[j+1];
                    posiciones[j+1]=temp;
                }
                else if(list[j] === list[j+1]){
                    let partJugados=valorNumero(infoClasificacion[posiciones[j]][6]);
                    let partJugados2=valorNumero(infoClasificacion[posiciones[j+1]][6]);
                    let tempNum=0;
                    let tempNum2=0;
                    if(partJugados>0) tempNum = (list[j] / partJugados);
                    if(partJugados2>0) tempNum2 = (list[j + 1] / partJugados2);

                    if(tempNum<tempNum2){
                        let temp = list[j];
                        list[j] = list[j+1];
                        list[j+1] = temp;
                        sorted = false;

                        temp=posiciones[j];
                        posiciones[j]=posiciones[j+1];
                        posiciones[j+1]=temp;
                    }
                    else if(tempNum===tempNum2){
                        if(partJugados<partJugados2){
                            let temp = list[j];
                            list[j] = list[j+1];
                            list[j+1] = temp;
                            sorted = false;

                            temp=posiciones[j];
                            posiciones[j]=posiciones[j+1];
                            posiciones[j+1]=temp;
                        }
                    }

                }
            }
            if(sorted){
                return posiciones;
            }
        }

    }catch(err){
        reportarError(nomClase, nomFuncion, "", err);
    }

    return posiciones;
}

//let isFirst=false;


const Detalle_Torneo = ({params="", definirModulo, modOrigen=0, paramOrigen=""}) => {
    const [esError, setEsError] = useState(0);
    const [pestana, setPestana] = useState("0");
    const [loading, setLoading] = useState(false);
    const [showMensaje, setShowMensaje] = useState(false);

    const [listaPestana7, setListaPestana7] = useState(false);
    const [faseE, setFaseE] = useState(0);
    const [hayPartidosFEUS, setHayPartidosFEUS] = useState(false);

    
   
    var respuesta = {
        datos: "",
        exito: false,
        conectado: false,
        hayDatos: false,
        mensaje: ''
    }

    useEffect(() => {
        const nomFuncion="useEffect";

        try{        
              
            yaConsultoInfoJugadores=false;
            //let ultParametros=recuperarDatoLocal("ultParametros" + idModulo);
            paramsAjustado=params;

            if(params!==""){
                let actualizar=false;
                let info=dividirLista(params,"#",5);
                idTorneo=info[0];
                //idTorneo=ultParametros;

                /*if(idTorneo==="" || idTorneo!==info[0]) {
                    actualizar=true;
                    guardarDatoLocal("ultParametros" + idModulo,info[0]);
                    idTorneo=info[0];
                } */



                if(!actualizar) {
                    actualizar= (isFlagUpdate() || superaTiempoActualizacion(idModulo + idTorneo));
                    
                }

                if(info[1]!=="" && info[1]!==pestana){
                    paramsAjustado=info[0] + "#" + info[1] + "#" + info[2] + "#" + info[3] + "#" + info[4];
                    setPestana(info[1]); 
                }  
                else {
                    paramsAjustado=info[0] + "#" + "2" + "#" + info[2] + "#" + info[3] + "#" + info[4];
                    setPestana("2"); 
                }

                
                if(info[2]==="jornada"){
                    if(info[4]!==""){
                        tipoLista="jornada";
                        idJornadaSel=info[4];
                    }
                }
                else tipoLista="fecha";

                //paramsBase=paramsAjustado;                        
                //actualizar=true;
                if(actualizar){
                    BD_solicitarDatos("INFO_INICIAL",[]);    
                }
                else{
                    cargarDatosGuardadosModulo();
                }
    
                
            }
    

        }catch(err){
            reportarError( nomClase, nomFuncion, "", err);
        }     
        

    }, [params]);

    if(params==="") return null;

    const BD_solicitarDatos = (tipoDatos, params) => {
        let nomFuncion="BD_solicitarDatos"; 

        try{
            let actualizar=true;
            let SEP=SEPARADOR_PARAMETROS_BD;
            let paramQuery="";
            let tipoConsulta="";
            let funcion="";
            let consulta="";
            let zonaHoraria="0";
            let mostrarError=true;
            let mostrarProcesando=true;

            let codigoFinal="";
            
            if(actualizar){                

                if(tipoDatos==="INFO_INICIAL"){
                    tipoConsulta="consultarTabla";
                    funcion="getListasDetalleTorneo";
                    consulta="INFO_DETALLE_TORNEO";
                    
                    paramQuery= idTorneo;
                } else if(tipoDatos==="INFO_DETALLE_TORNEO_JUGADORES"){
                    tipoConsulta="consultarTabla";
                    funcion="getListasDetalleTorneo";
                    consulta="INFO_DETALLE_TORNEO_JUGADORES";
                    yaConsultoInfoJugadores=false;
                    setListaPestana7(false);
                    
                    paramQuery= idTorneo;
                } 
                
                if(mostrarProcesando) setLoading(true);

                getDatos()
                .get(tipoConsulta,funcion,consulta,paramQuery,zonaHoraria)
                .then((res) => {
                    nomFuncion="BD_solicitarDatos.respuesta"; 

                    try{
                        respuesta=res;
                        if (respuesta.exito) {
                            if(tipoDatos==="INFO_INICIAL"){
                                presentarLog(nomClase + " - SOLICITA_DATOS"); 
    
                                //guardarDatoLocal("ultAcceso" + idModulo,Date.now());
                                guardarDatoLocal("ultAcceso" + idModulo + idTorneo,Date.now());
                                guardarDatoLocal("datos" + idModulo + idTorneo, respuesta.datos);
    
                                crearInfoClasificacion(crearTabla(respuesta.datos,"1"));
                                crearPartidosProgramados(crearTabla(respuesta.datos,"2"));
                                crearInfoJugadores(crearTabla(respuesta.datos,"3"));
                                crearInfoTorneo(crearTabla(respuesta.datos,"4"));
                                crearInfoSanciones(crearTabla(respuesta.datos,"5"));
                                crearInfoGruposFE(crearTabla(respuesta.datos,"6"));

                                if(infoPartidosProgramados.length>0) ordenarPartidosEliminacion();


                                let identificador2="1";
                                           
                                if(infoTorneoO.length>0){
                                    let esLinkTorneo=recuperarDatoLocal("esLinkTorneo");
                                    guardarDatoLocal("esLinkTorneo","0");

                                    if(esLinkTorneo==="1") identificador2="3";

                                    if(esLinkTorneo==="1" && infoTorneoO[0].activo!=="1"){
                                        titulo="TORNEO INACTIVO";
                                        mensaje="El torneo solicitado esta INHABILITADO por el Organizador del Torneo. La información NO esta disponible, consulte con el Organizador del torneo.";
                                        ventanaMensaje=showMensajePantalla(titulo,mensaje,setShowMensaje,salir,"ERROR","");
                                    }
                                    else{
                                        

                                        ordenarInfomacion();

                                        if(hayPartidosFE) setHayPartidosFEUS(true);
                                        else setHayPartidosFEUS(false);
                                    }
                                    
                                }
                                else{
                                    titulo="TORNEO NO ENCONTRADO";
                                    mensaje="El torneo solicitado NO fue encontrado, verifique la información y consulte nuevamente.";
                                    ventanaMensaje=showMensajePantalla(titulo,mensaje,setShowMensaje,salir,"ERROR","");
                                }
                                        
                                
            
                                registrarLogEventos(idModulo, "1", idTorneo, identificador2 );
                            } else if(tipoDatos==="INFO_DETALLE_TORNEO_JUGADORES"){
                                presentarLog(nomClase + " - SOLICITA_DATOS " + subModulo); 

                                //guardarDatoLocal("ultParametros" + idModulo + subModulo + idTorneo, idTorneo);
                                guardarDatoLocal("ultAcceso" + idModulo + subModulo + idTorneo,Date.now());
                                guardarDatoLocal("datos" + idModulo  + subModulo + idTorneo, respuesta.datos);

                                crearInfoJugadores(crearTabla(respuesta.datos,"3"));
                                setListaPestana7(true);
                                yaConsultoInfoJugadores=true;

                                registrarLogEventos(idModulo, "14", idTorneo,"1" );
                            }       
                                                       
            
                        } else {   
                            if(respuesta.conectado){
                                reportarError( nomClase, nomFuncion, respuesta.mensaje, "");
                                if(mostrarError) setEsError(1);
                            } 
                            else{    
                                presentarError(nomClase,nomFuncion,respuesta.mensaje,"");                        
                                setEsError(2);
                            } 
                        } 

                    }catch(err){
                        reportarError( nomClase, nomFuncion, "", err);
                    } 
                    
        
                    setLoading(false);
                    
                });
            }
            
           

        }catch(err){
            reportarError( nomClase, nomFuncion, "", err);
        }  
    } 

    const cargarDatosGuardadosModulo = () => {
        let nomFuncion="cargarDatosGuardadosModulo"; 

        try{             
            presentarLog(nomClase + " - RECUPERA_DATOS"); 
            let datosModulo=recuperarDatoLocal("datos" + idModulo + idTorneo);

            if(datosModulo!==""){
                crearInfoClasificacion(crearTabla(datosModulo,"1"));
                crearPartidosProgramados(crearTabla(datosModulo,"2"));
                crearInfoJugadores(crearTabla(datosModulo,"3"));
                crearInfoTorneo(crearTabla(datosModulo,"4"));
                crearInfoSanciones(crearTabla(datosModulo,"5"));
                crearInfoGruposFE(crearTabla(datosModulo,"6"));
                        
                if(infoPartidosProgramados.length>0) ordenarPartidosEliminacion();
                ordenarInfomacion();

                if(hayPartidosFE) setHayPartidosFEUS(true);
                else setHayPartidosFEUS(false);

                registrarLogEventos(idModulo, "1", idTorneo,"0" );
            }     
            else BD_solicitarDatos("INFO_INICIAL",[]);

        }catch(err){
            reportarError( nomClase, nomFuncion, "", err);
        } 
    }

    const cargarDatosGuardadosDTJModulo = () => {
        let nomFuncion="cargarDatosGuardadosDTJModulo"; 

        try{             
            presentarLog(nomClase + " - RECUPERA_DATOS " + subModulo); 
            let datosModulo=recuperarDatoLocal("datos" + idModulo + subModulo + idTorneo);

            if(datosModulo!==""){
                crearInfoJugadores(crearTabla(datosModulo,"3"));     
                setListaPestana7(true);
                yaConsultoInfoJugadores=true;      
                
                registrarLogEventos(idModulo, "14", idTorneo,"0" );
            }     
            else BD_solicitarDatos("INFO_DETALLE_TORNEO_JUGADORES");

        }catch(err){
            reportarError( nomClase, nomFuncion, "", err);
        } 
    }


    const clickMenu = (e, numPestana) => {
        if(pestana!==numPestana){
            let info=dividirLista(params,"#",5);
            paramsAjustado=info[0] + "#" + numPestana + "#" + info[2] + "#" + info[3] + "#" + info[4];
            setPestana(numPestana);    
        }
         
    }


    const ordenarPartidosEliminacion = () => {
        let nomFuncion="ordenarPartidosEliminacion";

        try{
            partidosEliminacionOrdenados=[];

            let partidosProgramados=JSON.parse(JSON.stringify(infoPartidosProgramados));

            if(partidosProgramados.length>0){
                let i=0, j=0;

                let listaPartidos=[];
                let partidosIdaVuelta=[];

                let orden=1;
                
                for(i=0;i<partidosProgramados.length;i++){
                    //Tipo Jornada fase de eliminación
                    if(partidosProgramados[i][44]===("2")){

                        if (partidosProgramados[i][50]===("1")) { // partidos Tipo Jornada Ida y Vuelta
                            let partidoEsta=false;
                            for(let k=0;k<partidosIdaVuelta.length;k++){
                                if(i===partidosIdaVuelta[k]){
                                    partidoEsta=true;
                                    k=partidosIdaVuelta.length;
                                }
                            }

                            if(!partidoEsta){

                                let idPartido=partidosProgramados[i][2];
                                let jornada=partidosProgramados[i][24];
                                let idEq1=partidosProgramados[i][5];
                                let idEq2=partidosProgramados[i][8];

                                let encontrado=false;

                                for(let k=0;k<partidosProgramados.length;k++){
                                    if(partidosProgramados[k][2]!==(idPartido) && partidosProgramados[k][24]===(jornada) &&
                                            ((partidosProgramados[k][5]===(idEq1) && partidosProgramados[k][8]===(idEq2)) ||
                                                    (partidosProgramados[k][5]===(idEq2) && partidosProgramados[k][8]===(idEq1)))){

                                        if(partidosProgramados[i][54]!==("0")){
                                            listaPartidos.push(i);
                                            partidosIdaVuelta.push(k);
                                        }
                                        else if(partidosProgramados[k][54]!==("0")){
                                            partidosIdaVuelta.push(i);
                                        }
                                        else{
                                            listaPartidos.push(i);
                                            partidosIdaVuelta.push(k);
                                        }

                                        encontrado=true;
                                        k=partidosProgramados.length;
                                    }
                                }

                                if(!encontrado) listaPartidos.push(i);

                            }

                        }
                        else listaPartidos.push(i);

                        if(partidosProgramados[i][54]!==("0")){
                            for(j=0;j<partidosProgramados.length;j++){
                                if(i!==j && partidosProgramados[i][54]===(partidosProgramados[j][2])){
                                    //partidosProgramados[j][54]=partidosProgramados[i][2];
                                    partidosProgramados[j][55]=partidosProgramados[i][55];
                                    partidosProgramados[i][56]="" + orden;
                                    partidosProgramados[j][56]="" + (orden+1);
                                    orden=orden+2;
                                    /*if(partidosProgramados[j][54].equals("0")){
                                        partidosProgramados[j][54]=partidosProgramados[i][2];
                                        //partidosProgramados[j][55]=partidosProgramados[i][55];
                                    }*/
                                    j=partidosProgramados.length;
                                }
                            }
                        }

                        /*if(partidosProgramados[i][3].equals("1")){
                            String []a=partidosProgramados[i];
                            Log.d("MarcaGol", "Jor: " + a[3] + " Eq1: " + a[6] + " Eq2: " + a[9] + " Orden1: " + a[55] + " Orden2: " + a[56]);
                        }*/

                    }
                }

                if(listaPartidos.length>0){
                    let list= [];

                    for(i=0;i<listaPartidos.length;i++){
                        list.push(listaPartidos[i]);
                    }

                    for(i =0; i< list.length; i++){
                        let sorted = true; // asumo que para la iteración i el listado es ordenado,
                        for(j =0; j< list.length - i - 1; j++){ // en cada iteración los elementos desde la posición (length-i) estan ordenados, por lo tanto solo recorro hasta esa posición


                            let numJornada1=valorNumero(partidosProgramados[list[j]][3]);
                            let numJornada2=valorNumero(partidosProgramados[list[j+1]][3]);
                            if(numJornada1 > numJornada2){
                                let temp = list[j];
                                list[j] = list[j+1];
                                list[j+1] = temp;
                                sorted = false;
                            }
                            else if(numJornada1 === numJornada2){
                                let orden1=valorNumero(partidosProgramados[list[j]][55]);
                                let orden2=valorNumero(partidosProgramados[list[j+1]][55]);

                                if(orden1>orden2 /*|| (orden1==0 && orden2>0)*/){
                                    let temp = list[j];
                                    list[j] = list[j+1];
                                    list[j+1] = temp;
                                    sorted = false;

                                }
                                else if(orden1 === orden2){
                                    let ordenF1=valorNumero(partidosProgramados[list[j]][56]);
                                    let ordenF2=valorNumero(partidosProgramados[list[j+1]][56]);

                                    if(ordenF1>ordenF2 /*|| (orden1==0 && orden2>0)*/){
                                        let temp = list[j];
                                        list[j] = list[j+1];
                                        list[j+1] = temp;
                                        sorted = false;

                                    }
                                }

                            }
                        }

                        if(sorted){
                            i = list.length;
                        }
                    }

                    //partidosEliminacionOrdenados =new String[listaPartidos.size()][partidosProgramados[0].length];

                    for(i=0;i<list.length;i++){
                        partidosEliminacionOrdenados.push(partidosProgramados[list[i]]);
                    }
                    
                }

               /*Log.d("MarcaGol", "Ordenada");
                for(int k=0;k<partidosEliminacionOrdenados.length;k++){
                    if(partidosEliminacionOrdenados[k][3].equals("1")){
                        String []a=partidosEliminacionOrdenados[k];
                        Log.d("MarcaGol", "Jor: " + a[3] + " Eq1: " + a[6] + " Eq2: " + a[9] + " Orden1: " + a[55] + " Orden2: " + a[56]);
                    }

                }*/

            }





        }catch(err){
            reportarError( nomClase, nomFuncion, "", err);
        } 

    }


    let regPublicidad=[];
    let imgAdd=[];    
    if(publicidad!==""){
        const nomFuncion="publicidad";

        try{
            regPublicidad=publicidad.split("[&");

            if(regPublicidad.length>0){
                let i;
                for(i=0;i<regPublicidad.length;i++){
                    let datosAdd=regPublicidad[i].split("[#");
                    let registroAdd =["",""];
        
                    if(datosAdd.length===1){
                        registroAdd[0]=ruta + datosAdd[0];
                        registroAdd[1]="";
                        imgAdd.push(registroAdd);
                    }
                    if(datosAdd.length===2){
                        registroAdd[0]=ruta + datosAdd[0];
                        registroAdd[1]=datosAdd[1];
                        imgAdd.push(registroAdd);
                    }
                }
            }

        }catch(err){
            reportarError( nomClase, nomFuncion, "", err);
        } 

        
    }

    function presentarPestana(){  
        const nomFuncion="presentarPestana";

        try{ 

            if((pestana==="1" && infoTorneoO.length>=1)) 
                return(<DT_InfoTorneo datos={infoTorneoO[0]} idTorneo={idTorneo} clickGenerico={clickGenerico}/>);
            if((pestana==="2")){
                if(faseE===0){
                    return(<DT_TablaClasificacion datos={infoClasificacionO} imgAdd={imgAdd}  modOrigen={idModulo} paramOrigen={paramsAjustado} definirModulo={definirModulo} />);
                }
                else{
                    return(<DT_PresentarFixture idTorneo={idTorneo}  partidosProgramados={partidosEliminacionOrdenados} infoClasificacion={infoClasificacion} infoGruposFE={infoGruposFE} volverFG={() => {setFaseE(0)}} infoTorneo={infoTorneo}/>);
                }
            }    
            if((pestana==="3")) 
                return(<DT_InfoPartidos tiempo_antes={tiempo_antes} datos={infoPartidosProgramadosO} infoFechas={infoFechas} idSemanaActual={idSemanaActual} infoJornadas={infoJornadas} idJornadaActual={idJornadaActual} tipoListaBase={tipoLista} modOrigen={idModulo} paramOrigen={paramsAjustado} definirModulo={definirModulo}/>);
            if((pestana==="4")) 
                return(<DT_InfoSanciones datos={infoSancionesO} />);
            if((pestana==="5")) 
                return(<DT_InfoEstadisticas idTorneo={idTorneo} infoClasificacion={infoClasificacion} columnasSuma={columnasSuma} columnasAvg={columnasAvg} tipo_registroinfo={tipo_registroinfo} numPartidos={numPartidos} cantJugadores={infoTorneoO[0].cantJugadores} tieneTarjetaAzul={tieneTarjetaAzul} />);
            if((pestana==="6")) 
                return(<DT_InfoRankingEquipos infoClasificacion={infoClasificacion} infoOrdenadaEquipos={infoOrdenadaEquipos} tipo_registroinfo={tipo_registroinfo} columnasASumar={columnasASumar}  modOrigen={idModulo} paramOrigen={paramsAjustado} definirModulo={definirModulo} tieneTarjetaAzul={tieneTarjetaAzul} hayPartidosFE={hayPartidosFE} />);
            if((pestana==="7")){
                if(!yaConsultoInfoJugadores){
                    if(!superaTiempoActualizacion(idModulo + subModulo + idTorneo)) cargarDatosGuardadosDTJModulo();
                    else BD_solicitarDatos("INFO_DETALLE_TORNEO_JUGADORES");
                    /*let ultParametro = recuperarDatoLocal("ultParametros" + idModulo + subModulo);
                    if(ultParametro !== idTorneo) BD_solicitarDatos("INFO_DETALLE_TORNEO_JUGADORES");  
                    else{
                        if(!superaTiempoActualizacion(idModulo + subModulo)) cargarDatosGuardadosDTJModulo();
                        else BD_solicitarDatos("INFO_DETALLE_TORNEO_JUGADORES");  
                    }   */                             
                } 
            }
            if((pestana==="8")) 
                return(<DT_PresentarFixture idTorneo={idTorneo}  partidosProgramados={infoPartidosProgramados} infoClasificacion={infoClasificacion} infoGruposFE={infoGruposFE}/>);
            else return(<></>);

        }catch(err){
            reportarError( nomClase, nomFuncion, "", err);
        } 
        
    }

    function presentarPestana7(){
        return(<DT_InfoRankingJugadores idTorneo={idTorneo} infoJugadores={infoJugadores}  tipo_registroinfo={tipo_registroinfo}  modOrigen={idModulo} paramOrigen={paramsAjustado} definirModulo={definirModulo} tieneTarjetaAzul={tieneTarjetaAzul} hayPartidosFE={hayPartidosFE} />);
    }


    const salir = () => {
        definirModulo(0,idModulo,paramsAjustado,modOrigen,paramOrigen);        
    }  

    
    const clickGenerico = (donde, params=null) => {
                let nomFuncion="clickGenerico"; 
        
                try{
                    
                    switch(donde) {
                          
                        case "CLICK_COPY_CLIPBOARD":

                            ventanaMensaje=showMensajePantalla("","Link COPIADO a Portapapeles.",setShowMensaje,null,"EXITO","BAJA");
    
                            break;
                        default:
                            break;
                    }
        
                }catch(err){
                    reportarError( nomClase, nomFuncion, "", err);
                }  
            } 

    return (
        <>

            {showMensaje && (ventanaMensaje)}

            <div className="CT_zonaFija">
                <div className="CT_encabezado">
                    <div className="CT_elementoEncabezado" onClick={salir}><img src={ic_salir} className="IM_imgIcono24" alt="" style={{padding: "8px"}} />  </div>
                    <div className="CT_elementoEncabezado"><img className="IM_imgRedondeada36" height="36px" width="36px" src={escudo} alt="" /></div>
                    <div className="CT_elementoEncabezado"><p className="TX_unaSolaLinea">{infoTorneoO.length>=1  ? (infoTorneoO[0].nombre) : ("")}</p></div>
                </div>
                <div className="CT_menuDeslizable">
                        <div className="CT_elementoDeslizable"><div className={pestana==="1" ? ("CT_menuSelected") : ("CT_menu")} onClick={(e) => clickMenu(e, "1")}>INFO</div></div>
                        <div className="CT_elementoDeslizable"><div className={pestana==="2" ? ("CT_menuSelected") : ("CT_menu")} onClick={(e) => clickMenu(e, "2")}>CLASIFICACIÓN</div></div>
                        <div className="CT_elementoDeslizable"><div className={pestana==="3" ? ("CT_menuSelected") : ("CT_menu")} onClick={(e) => clickMenu(e, "3")}>PARTIDOS</div></div>
                        <div className="CT_elementoDeslizable"><div className={pestana==="4" ? ("CT_menuSelected") : ("CT_menu")} onClick={(e) => clickMenu(e, "4")}>SANCIONES</div></div>
                        <div className="CT_elementoDeslizable"><div className={pestana==="5" ? ("CT_menuSelected") : ("CT_menu")} onClick={(e) => clickMenu(e, "5")}>ESTADÍSTICAS</div></div>
                        <div className="CT_elementoDeslizable"><div className={pestana==="6" ? ("CT_menuSelected") : ("CT_menu")} onClick={(e) => clickMenu(e, "6")}>RK EQUIPOS</div></div>
                        <div className="CT_elementoDeslizable"><div className={pestana==="7" ? ("CT_menuSelected") : ("CT_menu")} onClick={(e) => clickMenu(e, "7")}>RK JUGADORES</div></div>
                        {/*<div className="CT_elementoDeslizable"><div className={pestana==="8" ? ("CT_menuSelected") : ("CT_menu")} onClick={(e) => clickMenu(e, "8")}>FIXTURE</div></div>*/}
                </div>
            </div>
            
            <div style={{paddingTop: "110px"}}>    

                {!loading && esError===0 && pestana==="2" && faseE===0 && hayPartidosFEUS && ( 
                    <div style={{display:"flex", alignItems:"center", justifyContent:"center", gap:"10px", margin:"10px"}}>
                        <div style={{flex:"1"}}>
                            <div style={{minWidth:"100px", maxWidth:"250px"}} className={faseE===0 ? "BF_botonPrimaryFull" : "BF_botonBordeGris"} onClick={() => {setFaseE(0)}}>
                                <span>Fase de Grupos</span>
                            </div>
                        </div>
                        <div style={{flex:"1"}}>
                            <div style={{minWidth:"150px", maxWidth:"250px"}} className={faseE===1 ? "BF_botonPrimaryFull" : "BF_botonBordeGris"} onClick={() => {setFaseE(1)}}>
                                <span>Fase Eliminación</span>
                            </div>
                        </div>
                    </div> 
                )}            

                {!loading && esError===0 && ( presentarPestana() )}

                {!loading && esError===0 && pestana==="7" && listaPestana7===true && ( presentarPestana7() )}
                
            </div>

            {esError!==0 && <ShowError tipoError={esError} funcionCerrar={salir} />}

            {loading && <Loader tipo={"TOTAL"}/>}   
        </>
    );

};

export default Detalle_Torneo;