export const APP_VERSION_APP=1012

export const APP_ES_PRODUCCION = true;
export const SERVIDOR_PRINCIPAL = "https://app.marcagol.net/";
export const SERVIDOR_PRUEBAS = "https://app.markgol.com/";
export const SERVIDOR_WEB = "https://web.marcagol.net/";

export const WS_INICIAL = "ws/web_apiV221104.php"
export const WS_IMAGENES = "ws/cargarimagenes.php";
export const WS_ENVIARCORREO_PASSWORD = "ws/enviarcorreo.php";
export const WS_ENVIARCORREO_CONTACTO = "ws/correocontacto.php";

export const REGISTRAR_CONSOLE = false;


export const TIEMPO_ACTUALIZACION_INFO = 300000;  //300000
export const ARCHIVOS_POR_CARPETA = 5000;
export const MAX_NIVELES_FIXTURE_NORMAL = 6;

export const ID_MODULO_PRINCIPAL_JUGADOR = "1";
export const ID_MODULO_DETALLE_TORNEO = "2";
export const ID_MODULO_DETALLE_PARTIDO = "3";
export const ID_MODULO_DETALLE_EQUIPO = "4";
export const ID_MODULO_DETALLE_JUGADOR = "5";
export const ID_MODULO_INFO_EQUIPO = "6";
export const ID_MODULO_INFO_JUGADOR = "7";
export const ID_MODULO_GESTION_MICUENTA = "8";
export const ID_MODULO_ADMIN_JUGADORES = "12";
export const ID_MODULO_GESTION_IMAGENES = "29";
export const ID_MODULO_BASE = "34";
export const ID_MODULO_INFO_COBROS = "38";
export const ID_MODULO_INFO_COBROS_JUGADOR = "39";
export const ID_MODULO_PRINCIPAL_INVITADO = "41";
export const ID_MODULO_INFO_MENSAJES = "47";

export const LISTA_MODULOS = ["1","2","3","4","5","6","7","8","12","29","34","41","47"];

export const DIAS_SEMANA = ["Lunes", "Martes", "Miercoles", "Jueves", "Viernes", "Sabado", "Domingo"];

export const NUM_LETRAS = [
    ["J", "P", "F", "B", "X", "V", "H", "M", "U", "A"],
    ["X", "M", "N", "Z", "A", "I", "B", "L", "E", "R"],
    ["H", "T", "U", "J", "S", "A", "X", "D", "B", "C"],
    ["Y", "P", "V", "G", "M", "U", "C", "E", "A", "D"],
    ["P", "W", "L", "A", "E", "Z", "K", "U", "X", "T"],
    ["U", "X", "K", "D", "V", "J", "B", "E", "G", "C"],
    ["F", "U", "G", "C", "I", "H", "B", "K", "R", "M"],
    ["V", "S", "A", "Q", "D", "X", "I", "E", "Z", "L"],
    ["U", "A", "H", "G", "T", "C", "J", "Y", "D", "X"],
    ["G", "B", "N", "D", "L", "S", "V", "U", "J", "Q"]
];

export const SEPARADOR_PARAMETROS_BD = "&|#";

export const SEP_1 = "|";
export const SEP_2 = "&";
export const SEP_3 = "$";
export const SEP_4 = "#";

export const SEP_D_1 = "|&";
export const SEP_D_2 = "$#";
export const SEP_AD_1 = "[&";
export const SEP_AD_2 = "[#";

export const UNIFORMES_COLORES=[
    ["1","i/g/u/1.png","1","Blanco","Claro","","1"],
    ["2","i/g/u/2.png","1","Gris","Oscuro","","2"],
    ["3","i/g/u/3.png","1","Negro","Oscuro","","3"],
    ["4","i/g/u/4.png","1","Azul","Claro","","4"],
    ["5","i/g/u/5.png","1","Azul","Fuerte","","5"],
    ["6","i/g/u/6.png","1","Azul","Oscuro","","6"],
    ["7","i/g/u/7.png","1","Amarillo","Claro","","7"],
    ["8","i/g/u/8.png","1","Rojo","Fuerte","","8"],
    ["9","i/g/u/9.png","1","Rosado","Claro","","9"],
    ["10","i/g/u/10.png","1","Rosado","Fuerte","","10"],
    ["11","i/g/u/11.png","1","Verde","Claro","","11"],
    ["12","i/g/u/12.png","1","Verde","Fuerte","","12"],
    ["13","i/g/u/13.png","1","Verde","Oscuro","","13"],
    ["14","i/g/u/14.png","1","Morado","Violeta","","14"],
    ["15","i/g/u/15.png","1","Naranja","Durazno","","15"],
    ["16","i/g/u/16.png","1","Blanco","Negro","","16"],
    ["17","i/g/u/17.png","1","Blanco","Rojo","","17"],
    ["18","i/g/u/18.png","1","Negro","Blanco","","18"],
    ["19","i/g/u/19.png","1","Negro","Amarillo","","19"],
    ["20","i/g/u/20.png","1","Azul","Blanco","","20"],
    ["21","i/g/u/21.png","1","Azul","Negro","","21"],
    ["22","i/g/u/22.png","1","Rojo","Blanco","","22"],
    ["23","i/g/u/23.png","1","Rojo","Negro","","23"],
    ["24","i/g/u/24.png","1","Verde","Blanco","","24"],
    ["25","i/g/u/25.png","1","Verde","Negro","","25"],
    ["87","","1","SIN Uniforme","SIN","","26"]
];

export const UNIFORMES_EQUIPOS=[
    ["26","i/g/u/26.png","2","Blanco","Claro","Alemania","1"],
    ["27","i/g/u/27.png","2","Blanco","Azul","Argentina","2"],
    ["28","i/g/u/28.png","2","Rojo","Blanco","Arsenal1","3"],
    ["29","i/g/u/29.png","2","Amarillo","Claro","Arsenal2","4"],
    ["30","i/g/u/30.png","2","Rojo","Blanco","Atletico1","5"],
    ["31","i/g/u/31.png","2","Negro","Rojo","Atletico2","6"],
    ["32","i/g/u/32.png","2","Azul","Claro","Atletico3","7"],
    ["33","i/g/u/33.png","2","Azul","Rojo","Barcelona1","8"],
    ["34","i/g/u/34.png","2","Azul","Verde","Barcelona2","9"],
    ["35","i/g/u/35.png","2","Amarillo","Azul","Barcelona3","10"],
    ["36","i/g/u/36.png","2","Rojo","Azul","Barcelona4","11"],
    ["37","i/g/u/37.png","2","Azul","Rojo","Barcelona5","12"],
    ["38","i/g/u/38.png","2","Rojo","Claro","Bayern1","13"],
    ["39","i/g/u/39.png","2","Blanco","Claro","Bayern2","14"],
    ["40","i/g/u/40.png","2","Azul","Oscuro","Bayern3","15"],
    ["41","i/g/u/41.png","2","Rojo","Negro","Belgica","16"],
    ["42","i/g/u/42.png","2","Azul","Amarillo","Boca Juniors","17"],
    ["43","i/g/u/43.png","2","Amarillo","Negro","Borussia Dortmund","18"],
    ["44","i/g/u/44.png","2","Amarillo","Claro","Brasil","19"],
    ["45","i/g/u/45.png","2","Verde","Blanco","Celtics","20"],
    ["46","i/g/u/46.png","2","Azul","Oscuro","Chelsea1","21"],
    ["47","i/g/u/47.png","2","Blanco","Claro","Chelsea2","22"],
    ["48","i/g/u/48.png","2","Amarillo","Azul","Colombia1","23"],
    ["49","i/g/u/49.png","2","Azul","Oscuro","Colombia2","24"],
    ["50","i/g/u/50.png","2","Rojo","Blanco","Croacia","25"],
    ["51","i/g/u/51.png","2","Rojo","Amarillo","España","26"],
    ["52","i/g/u/52.png","2","Azul","Oscuro","Francia","27"],
    ["53","i/g/u/53.png","2","Azul","Negro","Inter","28"],
    ["54","i/g/u/54.png","2","Blanco","Negro","Juventus1","29"],
    ["55","i/g/u/55.png","2","Negro","Blanco","Juventus2","30"],
    ["56","i/g/u/56.png","2","Blanco","Negro","Juventus3","31"],
    ["57","i/g/u/57.png","2","Blanco","Claro","Juventus4","32"],
    ["58","i/g/u/58.png","2","Azul","Claro","Juventus5","33"],
    ["59","i/g/u/59.png","2","Negro","Amarillo","Juventus6","34"],
    ["60","i/g/u/60.png","2","Rojo","Escarlata","Liverpool1","35"],
    ["61","i/g/u/61.png","2","Blanco","Claro","Liverpool2","36"],
    ["62","i/g/u/62.png","2","Azul","Claro","M City1","37"],
    ["63","i/g/u/63.png","2","Negro","Oscuro","M City2","38"],
    ["64","i/g/u/64.png","2","Verde","Claro","Mexico1","39"],
    ["65","i/g/u/65.png","2","Negro","Oscuro","Mexico2","40"],
    ["66","i/g/u/66.png","2","Verde","Oscuro","Mexico3","41"],
    ["67","i/g/u/67.png","2","Rojo","Negro","Milan","42"],
    ["68","i/g/u/68.png","2","Rojo","Blanco","Monaco","43"],
    ["69","i/g/u/69.png","2","Rojo","Claro","M United1","44"],
    ["70","i/g/u/70.png","2","Beige","Claro","M United2","45"],
    ["71","i/g/u/71.png","2","Negro","Oscuro","M United3","46"],
    ["72","i/g/u/72.png","2","Blanco","Rojo","Peru","47"],
    ["73","i/g/u/73.png","2","Azul","Oscuro","PSG1","48"],
    ["74","i/g/u/74.png","2","Blanco","Claro","PSG2","49"],
    ["75","i/g/u/75.png","2","Naranja","Fuerte","PSG3","50"],
    ["76","i/g/u/76.png","2","Azul","Oscuro","PSG4","51"],
    ["77","i/g/u/77.png","2","Blanco","Claro","Real Madrid1","52"],
    ["78","i/g/u/78.png","2","Azul","Oscuro","Real Madrid2","53"],
    ["79","i/g/u/79.png","2","Verde","Claro","Real Madrid3","54"],
    ["80","i/g/u/80.png","2","Rojo","Claro","Real Madrid4","55"],
    ["81","i/g/u/81.png","2","Morado","Violeta","Real Madrid5","56"],
    ["82","i/g/u/82.png","2","Blanco","Rojo","River Plate","57"],
    ["83","i/g/u/83.png","2","Rojo","Escarlata","Roma","58"],
    ["84","i/g/u/84.png","2","Blanco","Claro","Tottenham1","59"],
    ["85","i/g/u/85.png","2","Azul","Oscuro","Tottenham2","60"],
    ["86","i/g/u/86.png","2","Amarillo","Fuerte","Villareal","61"],
    ["87","","1","SIN Uniforme","SIN","","26"]
];


export const REFERENCIA_PAGOS=[
    ["A","1","B","2","C","3","D","4","E","5","F","6","G","7","H","8","I","9","J","K","L","M","N","P","Q","R","S","T","U","V","W","X","Y","Z"],
    ["Z","4","Y","X","W","V","U","9","T","S","6","R","Q","5","P","3","N","M","L","8","K","J","I","2","H","G","F","1","E","D","7","C","B","A"],
    ["B","2","A","U","4","C","T","6","D","L","8","V","E","K","F","1","J","G","3","M","H","5","I","Q","7","Z","N","9","Y","R","X","S","W","P"],
    ["I","H","P","W","9","U","Y","8","V","J","7","C","A","N","6","5","G","R","K","B","4","Z","D","3","M","Q","X","2","L","S","1","E","T","F"],
    ["R","Q","2","X","E","D","1","C","P","Y","B","3","T","6","S","F","G","H","A","W","5","Z","4","U","J","I","9","K","L","V","7","N","M","8"],
    ["C","H","7","1","S","D","U","P","8","X","I","V","6","L","G","Y","4","M","Z","B","Q","T","5","N","R","2","E","A","W","9","J","K","F","3"]];


export const C_transparente = "#ffffff00";


export const C_colorPrimary = "#689F38";
export const C_colorPrimaryDark = "#3A3A3A";
export const C_colorAccent = "#D4E644";

export const C_fondoAccentSuave = "#d2e5432e";
export const C_fondoPrimarySuave = "#689f382e";
export const C_fondoPrimaryDarkSuave = "#3a3a3a2e";

export const C_colorMiCancha = "#38943B";
export const C_colorMiCancha2 = "#419f55";
export const C_tarjetaAmarilla = "#ffd000";
export const C_tarjetaRoja = "#ff0004";
export const C_tarjetaAzul = "#0059a9";
export const C_colorFondoModal = "#666566";
export const C_colorFondoSelecionado = "#D4E644";
export const C_colorTextoExito = "#689F38";
export const C_colorSinDatos = "#F81F1F";
export const C_colorTextoError = "#ff0000";
export const C_colorTextoSinInfo = "#007BFF";
export const C_colorModoSonido = "#ffffff40";
export const C_colorEscudo1 = "#B2D5F1";
export const C_colorEscudo2 = "#FAAD76";

export const C_black_overlay = "#00000099";
export const C_background = "#ececec";

export const C_uniformeRojo = "#ff0000";
export const C_uniformeRosa = "#FDB4B4";
export const C_uniformeAzulOscuro = "#000089";
export const C_uniformeAzulClaro = "#64B3F3";
export const C_uniformeVerdeOscuro = "#014901";
export const C_uniformeVerdeClaro = "#48B648";
export const C_uniformeNaranja = "#ff6a00";
export const C_uniformAmarillo = "#ffff00";
export const C_uniformeNegro = "#000000";
export const C_uniformeBlanco = "#ffffff";
export const C_uniformeGrisOscuro = "#5F5F5F";
export const C_uniformeGrisClaro = "#C5C5C5";
export const C_uniformeGrisSuperClaro = "#e2e2e27a";
export const C_uniformeGrisMedio = "#C5C5C5";
export const C_uniformeMorado = "#a552ae";

export const C_fondoPaginas = "#dedede";
export const C_fondoPaginasOscuro = "#B9B9B9";
export const C_fondoPaginasContraste = "#ffffff";
export const C_fondoPaginasTexto = "#000000";
export const C_fondoPaginasContrasteTextoPrincipal = "#000000";
export const C_fondoPaginasContrasteTextoSecundario = "#c1c1c1";
export const C_fondolistaspar2 = "#d4e64466";
export const C_fondoProcesando = "#3a3a3a96";
export const C_fondoInactivo = "#dcdcdc73";

export const C_fondolistas = "#F4F4F4";

export const C_fondolistaspar = "#d4e64433";
export const C_fondolistasparrecuadro = "#F6F8DF";
export const C_fondorecuadrorojo = "#FDB4B4";
export const C_fondorechazado = "#ffffffba";

export const C_fondoEncabezadoTabs = "#3A3A3A";

export const C_ocupado = "#ff01013d";
export const C_otrajornada = "#FDEDED";
export const C_nodisponible = "#FF4848C8";
export const C_disponible = "#15FF002D";
export const C_horaCancha = "#F3F3F379";