import React, { useState } from "react";

import "../estilos/BF_BordesFondos.css";
import "../estilos/IM_Imagenes.css";

import ic_transparente from '../recGraficos/ic_transparente.png';
import ic_gol_anulado from '../recGraficos/ic_gol_anulado.png';
import ic_balon_autogol from '../recGraficos/ic_balon_autogol.png';
import ic_balon_gol from '../recGraficos/ic_balon_gol.png';

import ic_tarjeta_dobleamarilla from '../recGraficos/ic_tarjeta_dobleamarilla.png';
import ic_amarilla from '../recGraficos/ic_amarilla.png';
import ic_tarjeta_roja from '../recGraficos/ic_tarjeta_roja.png';
import ic_cambiojugador from '../recGraficos/ic_cambiojugador.png';
import ic_inactivo from '../recGraficos/ic_inactivo.png';
import ic_activo from '../recGraficos/ic_activo.png';

import { C_transparente, C_uniformeBlanco, C_uniformeGrisMedio, C_uniformeGrisOscuro, C_uniformeNegro } from "../constantes/generales";
import { presentarLog } from "../helpers/funciones";


const Template_lista_detallepartido = ({datos}) => {

    //const [tipoVista, setTipoVista] = useState("ACCION");

    if(datos===null) return;

    let tipoVista="ACCION";
    let estiloTitulo="BF_cajaPrimary";
    let txtTitulo="";
    let colorTitulo=C_uniformeBlanco;

    let txtMinuto1="";
    let imgIcono1=ic_transparente;
    let txtMinuto2="";
    let imgIcono2=ic_transparente;
    let txtPrincipal="";
    let txtSecundario="";
    let alineacionTxt="right";

    let accion = {
        idregistro:"",
        idaccion:"",
        idequipo1:"",
        idequipo2:"",
        tiempopartido:"",
        minutopartido:"",
        idjugador1:"",
        idjugador2:"",
        idtipogol:"",
        jugador1:"",
        jugador2:"",
        eqlocal:"",
        eqvisitante:""
    }

    //presentarLog(datos);


    if(datos.idequipo1===""){

        tipoVista="TITULO";
        if(datos.idaccion==="0"){
            estiloTitulo="BF_cajaPrimary";
            txtTitulo=datos.jugador1;
            colorTitulo=C_uniformeBlanco;
        }
        else if(datos.idaccion==="-1"){
            estiloTitulo="BF_cajaGris";
            txtTitulo=datos.jugador1;
            colorTitulo=C_uniformeNegro;
        }
        else if(datos.idaccion==="-2"){
            estiloTitulo="BF_cajaAccent";
            txtTitulo=datos.jugador1;
            colorTitulo=C_uniformeNegro;
        }
    } 
    else if(datos.idequipo1==="0"){
        alineacionTxt="right";
        txtPrincipal=datos.jugador1;
        txtMinuto2=datos.minutopartido + "'";

        if(datos.idaccion==="1"){
            if(datos.idtipogol==="5"){
                imgIcono2=ic_gol_anulado;
                txtSecundario="Anulado";
            }
            else if(datos.idtipogol==="4"){
                imgIcono2=ic_balon_autogol;
                txtSecundario="Autogol";
            }
            else if(datos.idtipogol==="3"){
                imgIcono2=ic_balon_gol;
                txtSecundario="Penalti";
            }
            else if(datos.idtipogol==="2"){
                imgIcono2=ic_balon_gol;
                txtSecundario="Tiro Libre";
            }
            else{
                imgIcono2=ic_balon_gol;
                if(datos.jugador2==="" || datos.jugador2==="null"){
                    txtSecundario="";
                }
                else{
                    txtSecundario="Asiste: " + datos.jugador2;
                }
            }
        }
        else if(datos.idaccion==="4"){
            txtSecundario="";
            if(datos.idtipogol==="2") imgIcono2=ic_tarjeta_dobleamarilla;
            else imgIcono2=ic_amarilla;
        }
        else if(datos.idaccion==="5"){
            txtSecundario="";
            imgIcono2=ic_tarjeta_roja;
        }
        else if(datos.idaccion==="6"){
            txtSecundario="Sale: " + datos.jugador2;
            imgIcono2=ic_cambiojugador;
        }
        else if(datos.idaccion==="7"){
            txtMinuto2="";
            if(datos.idtipogol==="0"){
                imgIcono2=ic_inactivo;
                txtSecundario="Fallado";
            }
            else if(datos.idtipogol==="1"){
                imgIcono2=ic_activo;
                txtSecundario="Anotado";
            }
        }

        

    } 
    else{
        alineacionTxt="left";
        txtPrincipal=datos.jugador1;
        txtMinuto1=datos.minutopartido + "'";

        if(datos.idaccion==="1"){
            if(datos.idtipogol==="5"){
                imgIcono1=ic_gol_anulado;
                txtSecundario="Anulado";
            }
            else if(datos.idtipogol==="4"){
                imgIcono1=ic_balon_autogol;
                txtSecundario="Autogol";
            }
            else if(datos.idtipogol==="3"){
                imgIcono1=ic_balon_gol;
                txtSecundario="Penalti";
            }
            else if(datos.idtipogol==="2"){
                imgIcono1=ic_balon_gol;
                txtSecundario="Tiro Libre";
            }
            else{
                imgIcono1=ic_balon_gol;
                if(datos.jugador2==="" || datos.jugador2==="null"){
                    txtSecundario="";
                }
                else{
                    txtSecundario="Asiste: " + datos.jugador2;
                }
            }
        }
        else if(datos.idaccion==="4"){
            txtSecundario="";
            if(datos.idtipogol==="2") imgIcono1=ic_tarjeta_dobleamarilla;
            else imgIcono1=ic_amarilla;
        }
        else if(datos.idaccion==="5"){
            txtSecundario="";
            imgIcono1=ic_tarjeta_roja;
        }
        else if(datos.idaccion==="6"){
            txtSecundario="Sale: " + datos.jugador2;
            imgIcono1=ic_cambiojugador;
        }
        else if(datos.idaccion==="7"){
            txtMinuto1="";
            if(datos.idtipogol==="0"){
                imgIcono1=ic_inactivo;
                txtSecundario="Fallado";
            }
            else if(datos.idtipogol==="1"){
                imgIcono1=ic_activo;
                txtSecundario="Anotado";
            }
        }
    }



  return (
    <div style={{background: C_uniformeBlanco}}>
        {tipoVista==="TITULO" && (
            <div className={estiloTitulo} style={{fontSize:"14px", fontWeight:"bold", color: colorTitulo, marginBlock:"10px"}}> {txtTitulo} </div>
        )}

        {tipoVista==="ACCION" && (<>
            <div style={{display:"flex", flexWrap:"nowrap", alignItems:"center", fontSize:"14px", fontWeight:"bold", color:C_uniformeGrisOscuro, paddingBlock:"3px"}}>
                <div style={{fontSize:"12px", width:"30px"}}>{txtMinuto1}</div>
                <div><img className="IM_imgIcono16" style={{marginBlock:"10px", marginRight:"5px"}} src={imgIcono1} alt="" /></div>
                <div style={{flex:"1", textAlign: alineacionTxt}}>
                    <div>{txtPrincipal}</div>
                    <div style={{fontSize:"12px", fontWeight:"normal"}}>{txtSecundario}</div>
                </div>
                <div><img className="IM_imgIcono16" style={{marginBlock:"10px", marginLeft:"5px"}} src={imgIcono2} alt="" /></div>
                <div style={{fontSize:"12px", width:"30px"}}>{txtMinuto2}</div>
            </div>
            <div className='BF_lineaInferior'></div>
            </>
        )}      
      
    </div> 
  );
};

export default Template_lista_detallepartido;