// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

import { getMessaging, getToken, isSupported, onMessage } from "firebase/messaging";
import { guardarDatoLocal } from "../helpers/funciones";

const vapidKey = "BI06DhURAV2Y8Ld9GP6rAyPgS2vvcko4Lzyq4wYJsCFwPeXiuuxpfhlO-2hWc5l6WpQEa0zRQFDC6IpXNAAmY9I";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig =  {
    apiKey: "AIzaSyChXz50MhmAq4HyKX1ZUnSVj5Kc2EaZL9E",
    authDomain: "marcagol-9d358.firebaseapp.com",
    databaseURL: "https://marcagol-9d358.firebaseio.com",
    projectId: "marcagol-9d358",
    storageBucket: "marcagol-9d358.firebasestorage.app",
    messagingSenderId: "241012097198",
    appId: "1:241012097198:web:424974aaf368916fc12d3f",
    measurementId: "G-B5KNKW9SBC"
  };

// Initialize Firebase
initializeApp(firebaseConfig);
//const analytics = getAnalytics(appfb);

export const messaging = (async () => {
  try {      
      const isSupportedBrowser = await isSupported();
      if (isSupportedBrowser) {
          guardarDatoLocal("browserSoportado","1")
          return getMessaging();
      }
      guardarDatoLocal("browserSoportado","0");
      return null;

  } catch (err) {
      guardarDatoLocal("browserSoportado","");
      return null;
  }
  })();


  export const requestForToken = async (dispatch) => {
    try {

        const messagingResolve = await messaging;

        if(messagingResolve!==null){
            const currentToken = await getToken(messagingResolve, {vapidKey});
            if (currentToken) {
              guardarDatoLocal("tokenequipo",currentToken);
              guardarDatoLocal("ultGuardar_TOKEN_NOTIFIC",Date.now());
            }
            else {
                  // Show permission request UI
                guardarDatoLocal("tienePermisoNotificaciones","0")
                guardarDatoLocal("tokenequipo","");
            }
        }
        
    } catch (err) {
        
    }
    };


  export const requestPermission = () => {

    try{

        guardarDatoLocal("tienePermisoNotificaciones","0");
        Notification.requestPermission().then(permission => {
            guardarDatoLocal("ultGuardar_PERMISO_NOTIFIC",Date.now());
            if(permission === "granted"){ 
              guardarDatoLocal("tienePermisoNotificaciones","1");
              requestForToken();        
        
            }
            else{
              guardarDatoLocal("tienePermisoNotificaciones","-1");
              guardarDatoLocal("tokenequipo","");
            }
        });
        
    }catch (err) {
      guardarDatoLocal("tienePermisoNotificaciones","0");
    }
    
  
  };

// Handle incoming messages. Called when:
// - a message is received while the app has focus
// - the user clicks on an app notification created by a service worker `messaging.onBackgroundMessage` handler.
/*export const onMessageListener = () =>
  new Promise((resolve) => {    
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });*/
